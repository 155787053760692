import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Form} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

export default class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
        }
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{

    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <NavigationBar currentPage="template" />
                    {
                    window.innerWidth >= 600 ?
                        <div>

                        </div>
                        :
                        <div>

                        </div>
                    }
            </div>
        )
    }
}

let styles={

}
