import React from 'react';
import {Input,Button,Alert,Row,Col,Table,Modal,Form} from 'reactstrap'
import axios from 'axios'
import qs from 'qs'
import history from '../history'
import {reactLocalStorage} from 'reactjs-localstorage'
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen'
import {copyTable} from './global.js'
import QrReader from 'react-qr-reader'
import QRCode from "qrcode.react"
import * as GLOBAL from './global.js'
import ReactPaginate from 'react-paginate'
import GoogleMap from '../components/GoogleMap';

import {FaCheckCircle,FaTimesCircle,FaCopy,FaWhatsappSquare} from 'react-icons/fa'
import {AiOutlineQrcode} from 'react-icons/ai'
import {RiMoneyDollarCircleLine} from 'react-icons/ri'

export default class OfflineEvent extends React.Component {
    constructor(props) {
        super(props)
        this.sampleWhatsappGroupMessage=""+
            "恭喜你成功报名Albert老师，2天Online的【系统管理】！🎉\n\n"+
            "掌握战略、营销、组织三大核心的管理能力,是企业持续发展的根本。通过这门课程，你可以全面提升管理水平！\n\n"+
            "🎯这里有2个步骤来提升你的学习体验：\n\n"+
            "1）Set Reminder 日期 准时出席🗓\n"+
            "{{Whatsapp Timetable}}\n\n"+
            "2）加入WhatsApp community等待获取Live 学习的Meeting Link\n"+
            "👉{{Whatsapp Group Link}}"
        this.state = {
            loading:false,
            offlineEventList:[],
            participantList:[],
            participantCount:0,
            currentOfflineEvent:null,
            attendance:0,

            newOfflineEventModal:false,
            newFranchiseTitle:"",
            newFranchiseVenue:"",
            newFranchiseStartDate:null,
            newFranchiseEndDate:null,
            newFranchiseVenueAddress:"",
            newFranchiseVenueLat:"",
            newFranchiseVenueLng:"",
            newFranchiseSeat:null,
            
            selectionBuffer:null, //temporarily store selected company
            selectionBuffer2:null,  //temporarily store selected company and selected contact person
            selectContactPersonModal:false,
            selectHostCompanyModal:false,
            selectionMode:null, //main
            selectedMainHost:null,
            selectedOtherHost:[],
            inputPaymentType:"bank",
            paymentBankDetails:null,
            eventHostList:[],
            hostCompanyList:[],
            createPrice:null,
            createWhatsappGroupMessage:this.sampleWhatsappGroupMessage,

            paymentBankModal:false,
            displayBankDetail:null,

            editOfflineEventModal:false,
            editOfflineEventTitle:"",
            editOfflineEventStartDate:null,
            editOfflineEventEndDate:null,
            editOfflineEventVenue:"",
            editOfflineEventVenueAddress:"",
            editOfflineEventVenueLat:"",
            editOfflineEventVenueLng:"",
            editOfflineEventSeat:null,
            editOfflineEventPrice:null,
            editWhatsappGroupMessage:null,

            addParticipantModal:false,
            addParticipantUsername:null,
            addParticipantMode:"member",
            nonMemberName:"",
            nonMemberPhone:"",
            nonMemberEmail:"",
            nonMemberIndustry:"",

            removeParticipantModal:false,
            removeParticipantUsername:null,

            //QR code scanning
            scanQRCodeModal: false,
            scanInfo:"Scanning...",
            openQRCamera:false,
            scannerData:null,

            ticketStatusModal:false,
            ticketStatus:null,
            memberDetail:null,

            ticketQRCodeModal:false,
            ticketQRCodeUserDetail:"",
            ticketQRCode:null,

            manualMarkAttendanceModal:false,
            selectedParticipant:null,

            verifyModal:false,
            nonMemID:"",

            hostAgentCompanyCode:null,

            chooseNonMemberReferrerModal:false,
            agentList:[],
            selectedNonMemberReferrer:null,
            selectMemberModal:false,
            isStaff:false,
            memberList:[],
            itemPerPage:10,
            page:0,
            searchField:"",
            memberCount:0,
            totalPage:0,

            searchNonMemberReferrer:null,

            ableToManageSeat:false,
            adjustSeatModal:false,
            descriptionModal:false,
            eventHostDetailList:[],
            askSeatQuotas:{},
            askTotalAvailableSeat:"0",
            askPublicSeat:"0",
            askDescription:"",

            seatLogModal:false,
            currentLogList:[],
            logPageItem:5,
            logPage:0,
            totalLogPage:0,
            allLogCount:0,

            seatOverviewModal:false,
            totalSeatNumber:0,
            totalPublicSeat:0,

            archivedSeatLogModal:false,
            searchTerm:null,
            mode:"",
            totalMemberCount:0,

            testWhatsappMessageModal:false,
            testWhatsappPhoneNumber:null,
        }
        this.currentType=null
        this.eventName=null
        this.runningBarcodeScanner=false
        this.scanBarcodeCooldown = 500
        this.sessionMode="coming"
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(let input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="type"){
                this.currentType=value
            }else if(param=="name"){
                this.eventName=decodeURI(value)
            }
        }

        this.loadOfflineEventList()
    }

    loadOfflineEventList=()=>{
        this.setState({loading:true})
        const payload = {
            eventType:this.currentType,
        }
        axios
            .post(`sales/loadofflineeventlist`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //determine how many days span is each courses session, used for json attendance record of each day
                for(let offlineEvent of response.data.offlineEventList){
                    let attendance={}
                    let startDate=new Date(offlineEvent.startDate)
                    offlineEvent.eventDateObj=startDate.setHours(0,0,0)
                    let endDate=new Date(offlineEvent.endDate)
                    offlineEvent.endDateObj=endDate.setHours(23,59,59)
                    let currentDate=startDate
                    while(currentDate<endDate){
                        attendance[""+currentDate.getDate()]=0
                        currentDate.setDate(currentDate.getDate() + 1)
                    }
                    offlineEvent.attendance=attendance
                }

                //parse date
                for(let offlineEvent of response.data.offlineEventList){
                    let rawDateSplit=offlineEvent.startDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    offlineEvent.dateString=dateSplit[2]+"/"+dateSplit[1]+"/"+dateSplit[0]
                }

                this.sessionMode="coming"
                this.setState({
                    loading:false,
                    offlineEventList:response.data.offlineEventList,
                    isStaff:response.data.isStaff,
                    participantList:[],
                    currentOfflineEvent:null,
                    offlineEventID:null,
                    eventHostDetailList:[],
                    currentLogList:[],
                })
            })
            .catch(error =>{
                alert("Load offline event list error\n"+error)
                this.setState({loading:false})
            })
    }

    loadListofArchivedOfflineEvent= ()=>{
        this.setState({loading:true})
        const payload={
            eventType:this.currentType,
        }
        axios
            .post(`sales/loadlistofarchivedofflineevent`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                for(let offlineEvent of response.data){
                    let attendance={}
                    let startDate=new Date(offlineEvent.startDate)
                    let endDate=new Date(offlineEvent.endDate)
                    let currentDate=startDate
                    while(currentDate<=endDate){
                        attendance[""+currentDate.getDate()]=0
                        currentDate.setDate(currentDate.getDate()+1)
                    }
                    offlineEvent.attendance=attendance
                }

                for(let offlineEvent of response.data){
                    let rawDateSplit=offlineEvent.startDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    offlineEvent.dateString=dateSplit[2]+"/"+dateSplit[1]+"/"+dateSplit[0]
                }

                this.sessionMode="previous"
                this.setState({
                    loading:false,
                    offlineEventList:response.data,
                    participantList:[],
                    currentOfflineEvent:null,
                    offlineEventID:null,
                    eventHostDetailList:[],
                    currentLogList:[],
                })
            })
            .catch(error=>{
                alert("Load archived "+this.eventName+" list error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    createNewOfflineEvent=()=>{
        this.setState({
            newOfflineEventModal:true,
            selectedMainHost:null,
            selectedOtherHost:[],
            selectionBuffer:null,
            selectionBuffer2:null,
            paymentBankDetails:null,
            inputPaymentType:"bank",
            hostCompanyList:[],
            eventHostList:[],
            createPrice:null,
            createWhatsappGroupMessage:this.sampleWhatsappGroupMessage,
            testWhatsappPhoneNumber:null,
        })
    }
    createNewOfflineEventFunc=()=>{
        let mainHost=this.state.selectedMainHost
        if(mainHost!=null){
            mainHost=JSON.stringify(mainHost)
        }
        let otherHost=this.state.selectedOtherHost
        if(otherHost!=null){
            otherHost=JSON.stringify(otherHost)
        }

        //validate whatsapp group message
        if(this.state.createWhatsappGroupMessage.includes("{{")||this.state.createWhatsappGroupMessage.includes("}}")){
            alert(
                "In Whatsapp Group Message, please replace {{Whatsapp Timetable}} and {{Whatsapp Group Link}} with the event's schedule and group link.e.g.:\n\n"+
                "星期二  Oct 1 ：8pm - 10pm\n"+
                "星期三  Oct 2 ：8pm - 10pm\n\n"+
                "https://chat.whatsapp.com/HRioNr1Q0yJBJQkZekeXm8"
            )
            return
        }

        this.setState({loading:true})
        const payload = {
            type:this.currentType,
            title:this.state.newFranchiseTitle,
            startDate:this.state.newFranchiseStartDate,
            endDate:this.state.newFranchiseEndDate,
            venue:this.state.newFranchiseVenue,
            venueLat:this.state.newFranchiseVenueLat,
            venueLng:this.state.newFranchiseVenueLng,
            seat:this.state.newFranchiseSeat,
            mainHost:mainHost,
            otherHost:otherHost,
            eventPrice:this.state.createPrice,
            whatsappGroupMessage:this.state.createWhatsappGroupMessage,
        }
        axios
            .post(`sales/createnewofflineevent`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.setState({
                    newOfflineEventModal:false,
                    participantList:[],
                    currentOfflineEvent:null,
                })
                this.loadOfflineEventList()
            })
            .catch(error =>{
                if (error.response) {
                    if (error.response.status == 444) {
                        alert("Please choose a main host")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Create new "+this.eventName+" modal error\n"+error)
                this.setState({loading:false,newOfflineEventModal:false})
            })
    }

    editOfflineEvent=()=>{
        if(this.state.currentOfflineEvent){
            for(let offlineEvent of this.state.offlineEventList){
                if(offlineEvent.id==this.state.currentOfflineEvent.id){
                    let rawSplit=offlineEvent.startDate.split("+")[0]
                    let dateSplit=rawSplit.split("T")[0]
                    let timeSplit=rawSplit.split("T")[1].split(":")
                    let rawEndSplit=offlineEvent.endDate.split("+")[0]
                    let dateEndSplit=rawEndSplit.split("T")[0]
                    let timeEndSplit=rawEndSplit.split("T")[1].split(":")
                    this.setState({
                        editOfflineEventModal:true,
                        editOfflineEventTitle:offlineEvent.title,
                        editOfflineEventStartDate:dateSplit+"T"+timeSplit[0]+":"+timeSplit[1],
                        editOfflineEventEndDate:dateEndSplit+"T"+timeEndSplit[0]+":"+timeEndSplit[1],
                        editOfflineEventVenue:offlineEvent.venue,
                        editOfflineEventVenueLat:offlineEvent.latitude,
                        editOfflineEventVenueLng:offlineEvent.longitude,
                        editOfflineEventSeat:offlineEvent.seatNumber,
                        editWhatsappGroupMessage:offlineEvent.whatsappGroupMessage,
                        selectedMainHost:JSON.parse(JSON.stringify(offlineEvent.mainHost)),
                        selectedOtherHost:JSON.parse(JSON.stringify(offlineEvent.otherHost)),
                        editOfflineEventPrice:offlineEvent.price,
                        selectionBuffer:null,
                        selectionBuffer2:null,
                        paymentBankDetails:null,
                        inputPaymentType:"bank",
                    })
                    break
                }
            }
        }else{
            alert("Select an offline event to edit")
        }
    }
    editOfflineEventFunction=()=>{
        this.setState({loading:true})
        let mainHost=this.state.selectedMainHost
        if(mainHost!=null){
            mainHost=JSON.stringify(mainHost)
        }
        let otherHost=this.state.selectedOtherHost
        if(otherHost!=null){
            otherHost=JSON.stringify(otherHost)
        }
        const payload = {
            id:this.state.currentOfflineEvent.id,
            title:this.state.editOfflineEventTitle,
            startDate:this.state.editOfflineEventStartDate,
            endDate:this.state.editOfflineEventEndDate,
            venue:this.state.editOfflineEventVenue,
            venueLat:this.state.editOfflineEventVenueLat,
            venueLng:this.state.editOfflineEventVenueLng,
            seat:this.state.editOfflineEventSeat,
            eventPrice:this.state.editOfflineEventPrice,
            mainHost:mainHost,
            otherHost:otherHost,
            whatsappGroupMessage:this.state.editWhatsappGroupMessage,
        }
        axios
            .post(`sales/editofflineevent`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.setState({
                    editOfflineEventModal:false,
                })
                this.loadOfflineEventList()
                if(this.state.currentOfflineEvent==null){
                    this.setState({participantList:[],loading:false,selectedParticipant:null})
                }else{
                    this.loadParticipant()
                }
            })
            .catch(error =>{
                if (error.response) {
                    if (error.response.status == 444) {
                        alert("Please choose a main host")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Edit "+this.eventName+" error\n"+error)
                this.setState({loading:false,editOfflineEventModal:false})
            })
    }

    loadParticipant=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            id:this.state.currentOfflineEvent.id,
        }
        axios
            .post(`sales/loadofflineeventparticipant`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //count attendance
                let att=0
                for(let booking of response.data.participantList){
                    if(booking.attend){
                        att+=1
                    }
                }

                //parse date
                for(let f=0;f<response.data.participantList.length;f++){
                    let rawDateSplit=response.data.participantList[f].signupDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    response.data.participantList[f].signupDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    participantList:response.data.participantList,
                    hostAgentCompanyCode:response.data.hostAgentCompanyCode,
                    participantCount:response.data.participantCount,
                    ableToManageSeat:response.data.ableToManageSeat,
                })
            })
            .catch(error =>{
                alert("Load "+this.eventName+" participant error\n"+error)
                this.setState({loading:false})
            })
    }

    loadArchivedOfflineEventParticipant=()=>{
        this.setState({loading:true})
        const payload={
            id:this.state.currentOfflineEvent.id,
        }
        axios
            .post(`sales/loadarchivedofflineeventparticipant`, qs.stringify(payload), {timeout:30000})
            .then(async response=>{
                for(let participant of response.data.participantList){
                    let rawDateSplit=participant.signupDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    participant.signupDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    participantList:response.data.participantList,
                    participantCount:response.data.participantCount
                })
            }).catch(error=>{
                alert("Load archived "+this.eventName+" participant error\n"+error)
                this.setState({loading:false})
            })
    }

    addParticipant=()=>{
        if(this.state.currentOfflineEvent){
            this.setState({addParticipantModal:true,addParticipantUsername:null})
        }else{
            alert("Select a "+this.eventName+" to add participant")
        }
    }
    addParticipantFunc=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        let payload;
        if(this.state.addParticipantUsername){
            payload = {
                offlineEventID:this.state.currentOfflineEvent.id,
                name:null,
                phone:this.state.addParticipantUsername,
                email:"",
                system:true,
            }
        }else{
            //validate
            if(this.state.nonMemberName==null||this.state.nonMemberName==""){
                alert("Name cannot be empty")
                return
            }

            let referrerUsername=null
            if(this.state.selectedNonMemberReferrer!=null){
                referrerUsername=this.state.selectedNonMemberReferrer.phone?this.state.selectedNonMemberReferrer.phone:this.state.selectedNonMemberReferrer.username
            }

            payload = {
                offlineEventID:this.state.currentOfflineEvent.id,
                name:this.state.nonMemberName,
                phone:this.state.nonMemberPhone,
                email:this.state.nonMemberEmail,
                industry:this.state.nonMemberIndustry,
                referrer:referrerUsername,
                agentCompany:this.state.hostAgentCompanyCode,
                system:true,
            }
        }

        axios
            .post(`membership/v1/signupofflineevent`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.setState({
                    loading:false,
                    addParticipantModal:false,
                    addParticipantUsername:null,
                })
                this.loadParticipant()
            })
            .catch(error =>{
                if(error.response){
                    if (error.response.status == 444) { //already signup with same phone
                        alert("The user already signed up to a "+this.eventName+" session. Each user can only sign up to one "+this.eventName+" at the same time.")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==445){   //franchise no found
                        alert(this.eventName+" session not found, please refresh the page.")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==446){   //franchise is already due
                        alert("The "+this.eventName+" is already due, please refresh the page.")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==447){   //seat is full
                        alert("Seat is full")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==449){   //cannot find user
                        alert("Cannot find user")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Add "+this.eventName+" participant error\n"+error)
                this.setState({
                    loading:false,
                    addParticipantModal:false,
                    addParticipantUsername:null,
                })
            })
    }

    deleteOfflineEvent=()=>{
        if(this.state.currentOfflineEvent==null){
            alert("Select a "+this.eventName+" to remove")
            return
        }

        if(!window.confirm("Are you sure you want to delete this "+this.eventName+", all unarchived booking record will be lost.")){
            return
        }

        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            offlineEventID:this.state.currentOfflineEvent.id,
        }
        axios
            .post(`sales/deleteofflineevent`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.loadOfflineEventList()
            })
            .catch(error =>{
                alert("Delete offline event error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    archiveOfflineEvent=()=>{
        if(this.state.currentOfflineEvent==null){
            alert("Select a "+this.eventName+" to archive")
            return
        }
        if(!window.confirm("Are you sure you want to archive this "+this.eventName+"?")){
            return
        }

        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            offlineEventID:this.state.currentOfflineEvent.id
        }
        axios
            .post(`sales/archiveofflineevent`, qs.stringify(payload),{timeout:180000}) //with extra timeout
            .then(async response => {
                alert(this.state.currentOfflineEvent.title+" successfully archived")
                this.setState({participantList:[],currentOfflineEvent:null})
                this.loadListofArchivedOfflineEvent()
            })
            .catch(error =>{
                alert("Archive "+this.eventName+" error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    fallbackCopyTextToClipboard=(text)=>{
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textArea.contentEditable;
            textArea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textArea.setSelectionRange(0, 999999);
            textArea.contentEditable = editable;
        }else {
            textArea.select();
        }

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful. If you are using Safari, Edge or IE please kindly use other browser to try.';
            alert('Copying text ' + msg);
        } catch (err) {
            alert('Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    }

    saveRemark=(ticketNumber,remark)=>{
        this.setState({loading:true})
        const payLoad = {
            ticketNumber:ticketNumber,
            remark:remark,
        }
        axios
            .post(`sales/saveofflineeventbookingremark`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({loading:false})
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Save "+this.eventName+" booking remark error\n" + error)
                this.setState({loading: false,})
            });
    }

    generateAttendanceButton=(csd)=>{
        let theAttendance=this.state.currentOfflineEvent.attendance
        let ele=[]
        let inEventDate=false
        let attendingToday=false

        if(this.sessionMode=="coming"){
            //check if today is event date
            let startDate=this.state.currentOfflineEvent.eventDateObj
            let endDate=this.state.currentOfflineEvent.endDateObj
            let currentDate=new Date()
            if(currentDate>=startDate&&currentDate<=endDate){
                inEventDate=true
            }else{
                inEventDate=false
            }
        }else if(this.sessionMode=="previous"){
            inEventDate=true
        }

        //check the member is attending today
        let todayDigit=""+new Date().getDate()
        if(this.sessionMode=="coming"){
            if(csd.attendance!=null&&inEventDate==true&&todayDigit in csd.attendance&&csd.attendance[todayDigit]==true){
                attendingToday=true
            }
        }else if(this.sessionMode=="previous"){
            if(csd.attend==true){
                attendingToday=true
            }
        }

        //draw three notifications
        Object.keys(theAttendance).forEach(function(key,index) {
            if(csd.attendance!=null&&key in csd.attendance&&csd.attendance[key]==true){   //attend
                ele.push(
                    <div style={{height:5,width:5,borderRadius:10,backgroundColor:"green"}} />
                )
            }else{  //not attend
                ele.push(
                    <div style={{height:5,width:5,borderRadius:10,backgroundColor:"red"}} />
                )
            }
        })

        //draw final button
        return(
            <div style={{display:"flex",flexDirection:"row"}}>
                <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",gap:2,marginRight:2}}>{ele}</div>
                {
                inEventDate==true?
                    attendingToday==true?
                        <Button color="primary" style={{padding:"2px 10px",fontSize:15,lineHieght:1,backgroundColor:"green",border:"none"}} onClick={()=>{
                            if(this.sessionMode=="previous"){
                                return
                            }
                            this.setState({manualMarkAttendanceModal:true,selectedParticipant:csd})
                        }}>
                            V
                        </Button>
                        :
                        <Button color="primary" style={{padding:"2px 10px",fontSize:15,lineHieght:1,backgroundColor:"red",border:"none"}} onClick={()=>{
                            if(this.sessionMode=="previous"){
                                return
                            }
                            this.setState({manualMarkAttendanceModal:true,selectedParticipant:csd})
                        }}>
                            X
                        </Button>
                    :
                    <Button color="secondary" style={{padding:"2px 10px",fontSize:15,lineHieght:1,}} onClick={()=>{
                        if(this.sessionMode=="previous"){
                            return
                        }
                        this.setState({manualMarkAttendanceModal:true,selectedParticipant:csd})
                    }}>
                        -
                    </Button>
                }
            </div>
        )
    }

    calculateAndGenerateAttendance=()=>{
        if(this.state.currentOfflineEvent==null){
            return null
        }

        let theAttendance=this.state.currentOfflineEvent.attendance        
        let totalParticipant=this.state.participantList.length
        let participant=this.state.participantList
        let ele=[]
        Object.keys(theAttendance).forEach(function(key,index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object

            //count
            theAttendance[key]=0
            for(let csd of participant){
                if(csd.attendance!=null&&csd.attendance[key]!=null&&csd.attendance[key]==true){
                    theAttendance[key]+=1
                }
            }

            //generate ui component
            ele.push(
                <div style={{padding:"5px 10px",border:"1px solid lightgrey"}}>
                    <i style={{color:"grey"}}>{key}th</i> : {theAttendance[key]} / {totalParticipant}
                </div>
            )
        })
        return ele
    }

    removeOfflineEventParticipant=()=>{
        this.setState({loading:true})
        const payLoad = {
            offlineEventID:this.state.currentOfflineEvent.id,
            participantUsername:this.state.removeParticipantUsername.trim(),
        }
        axios
            .post(`sales/removeofflineeventparticipant`, qs.stringify(payLoad))
            .then(async response => {
                alert(this.state.removeParticipantUsername+" is removed from "+this.state.currentOfflineEvent.title)
                this.setState({
                    loading:false,
                    removeParticipantUsername:null,
                    removeParticipantModal:false,
                })
                this.loadParticipant()
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Remove participant from franchise error\n" + error)
                this.setState({loading: false,})
            });
    }

    //QR code scanning
    handleScan = data => {
        if(this.state.scanInfo=="Checking..."||this.state.ticketStatusModal==true)return
        if (data) {
            this.setState({loading:true,scanInfo:"Checking..."})
            const payload = {
                data:data,
                offlineEventID:this.state.currentOfflineEvent.id,
            }
            axios
                .post(`sales/scanofflineeventattendance`, qs.stringify(payload),{timeout:20000}) //with extra timeout
                .then(async response => {
                    //mark
                    let participantListClone=this.state.participantList
                    for(let participant of participantListClone){
                        if(participant.id==response.data.booking.id){
                            participant.attend=response.data.booking.attend
                            participant.attendance=response.data.booking.attendance
                            break
                        }
                    }
                    this.setState({
                        loading:false,
                        scanInfo:"Scanning...",
                        scannerData:"",
                        ticketStatusModal:true,
                        ticketStatus:"success",
                        memberDetail:response.data.memberDetail,
                        participantList:participantListClone,
                    })
                })
                .catch(error =>{
                    if(error.response){
                        if(error.response.status==444){
                            this.setState({
                                loading:false,
                                scanInfo:"Scanning...",
                                scannerData:"",
                                ticketStatusModal:true,
                                ticketStatus:"already",
                                memberDetail:error.response.data.memberDetail,
                            })
                            return
                        }else if(error.response.status==445){
                            this.setState({
                                loading:false,
                                scanInfo:"Scanning...",
                                scannerData:"",
                                ticketStatusModal:true,
                                ticketStatus:"notFound",
                                memberDetail:null,
                            })
                            return
                        }
                    }
                    alert("Scan attendance error\n"+error)
                    this.setState({
                        loading:false,
                        scanInfo:"Scanning...",
                        scannerData:"",
                        memberDetail:null,
                    })
                })
        }
    }
    handleError = err => {
        alert("QR code scanner error\n" + err)
        this.setState({ scanQRCodeModal: false })
    }

    getTicketQRCodeLink=(item)=>{
        let userString="-"
        if(item.user!=null){
            userString=item.user.username+" "+item.user.name
        }else{
            userString=item.phone+" "+item.name
        }
        this.setState({
            ticketQRCodeModal:true,
            ticketQRCodeUserDetail:userString,
            ticketQRCode:item.ticketNumber,
        })
    }

    generatePaticipantAttendance=()=>{
        let ele=[]
        let theAttendance=this.state.currentOfflineEvent.attendance
        let selectedParticipant=this.state.selectedParticipant
        let component=this
        Object.keys(theAttendance).forEach(function(key,index) {
            if(selectedParticipant.attendance!=null&&key in selectedParticipant.attendance&&selectedParticipant.attendance[key]==true){   //attend
                ele.push(
                    <Button color="primary" style={{backgroundColor:"green",border:"none"}} onClick={()=>{
                        component.markOfflineEventBookingAttend(key)
                    }}>
                        {key}th
                    </Button>
                )
            }else{  //not attend
                ele.push(
                    <Button color="primary" style={{backgroundColor:"red",border:"none"}} onClick={()=>{
                        component.markOfflineEventBookingAttend(key)
                    }}>
                        {key}th
                    </Button>
                )
            }
        })
        return ele
    }

    markOfflineEventBookingAttend=(key)=>{
        this.setState({loading:true})
        const payload = {
            offlineEventBookingID:this.state.selectedParticipant.id,
            attendanceKey:key,            
        }
        axios
            .post(`sales/markofflineeventbookingattend`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //mark
                let participantListClone=this.state.participantList
                for(let participant of participantListClone){
                    if(participant.id==response.data.id){
                        participant.attend=response.data.attend
                        participant.attendance=response.data.attendance
                        break
                    }
                }
                this.setState({
                    loading:false,
                    participantList:participantListClone,
                })
            })
            .catch(error =>{
                alert("Mark attend "+this.eventName+" error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    getEventHostList=()=>{
        this.setState({loading:true})
        const payLoad = {

        }
        axios.post(`sales/geteventhostlist`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({
                    loading:false,
                    selectContactPersonModal:true,
                    eventHostList:response.data,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Get event host list error\n" + error)
                this.setState({loading: false,})
            })
    }

    getHostCompanyList=()=>{
        this.setState({loading:true})
        const payLoad = {

        }
        axios.post(`sales/gethostcompanylist`, qs.stringify(payLoad))
            .then(async response => {
                this.setState({
                    loading:false,
                    selectHostCompanyModal:true,
                    hostCompanyList:response.data,
                    selectionBuffer1:null,
                    selectionBuffer2:null,
                    paymentBankDetails:null,
                    inputPaymentType:"bank",
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account is logged in another device, please relog")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Get host company list error\n" + error)
                this.setState({loading:false})
            })
    }

    markOfflineEventBookingPaid=(booking)=>{
        this.setState({loading:true})
        const payload = {
            bookingID:booking.id,
        }
        axios.post(`sales/markofflineeventbookingpaid`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //mark
                let participantListClone=this.state.participantList
                for(let participant of participantListClone){
                    if(participant.id==booking.id){
                        if(booking.paid==true){
                            participant.paid=false
                        }else{
                            participant.paid=true
                        }
                        break
                    }
                }
                //count paidTotal
                let paidTotal=0
                for(let participant of participantListClone){
                    if(participant.paid==true){
                        paidTotal+=1
                    }
                }
                this.setState({loading:false,participantList:participantListClone,paidTotal:paidTotal})
            })
            .catch(error =>{
                alert("Mark paid "+this.eventName+" error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    sendVerifyLink=(bookingID,num)=>{
        this.setState({loading:true})
        const payLoad = {
            bookingID:bookingID,
            num:num,
        }
        
        axios
            .post(`sales/sendmemberverificationlink`,qs.stringify(payLoad))
            .then(async response => {
                alert("Link successfully sent")
                this.setState({
                    loading:false,  
                    verifyModal:false,
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status==446){
                        alert("Please wait 30 seconds.\nVerification code request too frequent")
                        this.setState({loading:false})
                        return
                    }else if(error.response.status==447){
                        alert("Whatsapp sending failed")
                        this.setState({loading:false})
                        return
                    }
                }
                alert("Send link error\n" + error)
                this.setState({loading: false,})
            })
    }

    getCompanyAgentList=()=>{
        this.setState({loading:true})
        const payload = {

        }
        axios
            .post(`sales/getcompanyagentlist`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    chooseNonMemberReferrerModal:true,
                    agentList:response.data,
                    mode:"agent"
                })
            })
            .catch(error => {
                if(error.response){
                    
                }
                alert("Get company agent list error\n" + error)
                this.setState({loading: false,})
            })
    }

    getHigherCompanyAgentList=()=>{
        this.setState({loading:true})
        const payload={
            searchNonMemberReferrer:this.state.searchNonMemberReferrer,
        }
        axios
            .post(`sales/gethighercompanyagentlist`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    agentList:response.data,
                })
            })
            .catch(error => {
                if(error.response){
                    
                }
                alert("Get higher company agent list error\n" + error)
                this.setState({loading: false,})
            })
    }

    getAllMember=()=>{
        this.setState({
            itemPerPage:10,
            loading:true,
        })
        const payload = {
            itemPerPage:this.state.itemPerPage,
            page:this.state.page,
            searchField:this.state.searchField,
        }
        axios
            .post('sales/getallmember',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                //parse the date
                for(var item of response.data.memberList){
                    var parsedDate=item.date_joined
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.date_joined=parsedDate
                }
                //parse the date
                for(var item of response.data.memberList){
                    var parsedDate=item.expirydate
                    parsedDate=parsedDate.split("T")
                    parsedDate=parsedDate[0].split("-")
                    parsedDate=parsedDate[2]+"."+parsedDate[1]+"."+parsedDate[0]
                    item.expirydate=parsedDate
                }

                this.setState({
                    memberList:response.data.memberList,
                    page:response.data.page,
                    memberCount:response.data.theMemberCount,
                    totalPage:response.data.totalPages,
                    loading:false,
                    selectMemberModal:true,
                })
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 444) {
                        // Error message returned by the server
                        alert('Select Member modal error:\n' + error.response.data.message)
                        this.setState({ loading: false })
                        return
                    }
                }
                // General error message directly from error object
                alert('Select Member modal error:\n' + error.message)
                this.setState({ loading: false })
            })  
    }

    getCompanyAgentMemberList=()=>{
        this.setState({loading:true})
        const payload={
            searchTerm:this.state.searchTerm,
            itemPerPage:this.state.itemPerPage,
            page:this.state.page
        }
        axios
            .post(`sales/getcompanyagentmemberlist`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    memberList:response.data.memberList,
                    mode:"member",
                    totalPage:response.data.totalPage,
                    totalMemberCount:response.data.totalMemberCount
                })
            })
            .catch(error => {
                if(error.response){
                    
                }
                alert("Get selected agent company member error\n" + error)
                this.setState({loading: false})
            })
    }

    getSelectedCompanyEventHost=()=>{
        this.setState({loading:true})
        const payload={
            selectedCompany:this.state.selectionBuffer.id,
        }
        axios
            .post(`sales/getselectedcompanyeventhost`,qs.stringify(payload))
            .then(async response => {
                this.setState({
                    loading:false,
                    eventHostList:response.data,
                    selectContactPersonModal:true,
                })
            })
            .catch(error => {
                if(error.response){
                    
                }
                alert("Get selected agent company event host error\n" + error)
                this.setState({loading: false})
            })
    }

    getEventHostDetailList=()=>{
        this.setState({loading:true})
        const payload={
            id:this.state.currentOfflineEvent.id,
        }
        axios
            .post(`sales/geteventhostdetaillist`,qs.stringify(payload))
            .then(async response => {
                // Sort the response data to ensure the main host is first
                const sortedHosts = response.data.eventHostDetailList.sort((a, b) => {
                    if (a.type === "main") return -1
                    if (b.type === "main") return 1
                    return 0;
                })

                this.setState({
                    loading:false,
                    eventHostDetailList:sortedHosts,
                    adjustSeatModal:true,
                    askTotalAvailableSeat:response.data.totalSeatNumber,
                    askPublicSeat:response.data.publicSeat,
                    askSeatQuotas: sortedHosts.reduce((acc, host) => {
                        acc[host.id] = host.seatQuota
                        return acc
                    }, {}),
                })
            })
            .catch(error => {
                if(error.response){
                    
                }
                alert("Get event host detail list error\n" + error)
                this.setState({loading: false})
            })
    }

    handleSeatQuotaChange(hostId,newValue) {
        if (newValue=="") {
            return
        }
        this.setState(prevState=>{
            // Increased seat quota for other host
            const increasedSeatQuota = parseInt(newValue) - parseInt(prevState.askSeatQuotas[hostId])

            return {
                askSeatQuotas: {
                    ...prevState.askSeatQuotas,
                    [hostId]:newValue,
                },
                askPublicSeat: parseInt(prevState.askPublicSeat) - increasedSeatQuota
            }
        })
    }

    handleTotalAvailableSeatChange(newValue) {
        if (newValue=="") {
            return
        }
        this.setState(prevState=>{
            const updatedSeatQuota = parseInt(newValue) - parseInt(prevState.askTotalAvailableSeat) + parseInt(prevState.askPublicSeat)

            return {
                askTotalAvailableSeat:newValue,
                askPublicSeat:updatedSeatQuota
            }
        })
    }

    updateEventHostSeat=()=>{
        this.setState({loading:true})
        const payload = {
            id:this.state.currentOfflineEvent.id,
            askTotalAvailableSeat:this.state.askTotalAvailableSeat,
            askPublicSeat:this.state.askPublicSeat,
            askSeatQuotas:JSON.stringify(this.state.askSeatQuotas),
            askDescription:this.state.askDescription,
        }
        axios
            .post(`sales/updateofflineeventhost`,qs.stringify(payload))
            .then(async response => {
                alert("Update offline event host successfully")
                this.setState({
                    loading:false,
                    adjustSeatModal:false,
                    descriptionModal:false,
                    editOfflineEventModal:false,
                    askSeatQuotas:{},
                    askTotalAvailableSeat:"0",
                    askPublicSeat:"0",
                    askDescription:"",
                },()=>{
                    this.loadOfflineEventList()
                })
            })
            .catch(error => {
                if(error.response){
                    if (error.response.status == 444) {
                        alert("Please correct the error")
                        this.setState({loading:false,descriptionModal:false,askDescription:""})
                        return
                    } else if (error.response.status == 445) {
                        alert("Remain unchanged")
                        this.setState({loading:false,adjustSeatModal:false,descriptionModal:false})
                        return
                    }
                }
                alert("Update offline event host error\n" + error)
                this.setState({loading: false,})
            })
    }

    getOfflineEventSeatLog=()=>{
        this.setState({loading:true})
        const payload = {
            id:this.state.currentOfflineEvent.id,
            itemPerPage:this.state.logPageItem,
            page:this.state.logPage,
        }
        axios
            .post('sales/getofflineeventseatlog',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                for(let log of response.data.currentLogList){
                    if(log.createDate){
                        let rawDateSplit=log.createDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        log.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                    }
                }

                this.setState({
                    loading:false,
                    seatLogModal:true,
                    currentLogList:response.data.currentLogList,
                    totalLogPage:response.data.totalLogPage,
                    logPage:response.data.logPage,
                    allLogCount:response.data.allLogCount,
                })
            })
            .catch(error => {
                if(error.response){

                }
                // General error message directly from error object
                alert('Get offline event seat log error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    getArchivedEventHostDetailList=()=>{
        this.setState({loading:true})
        const payload={
            id:this.state.currentOfflineEvent.id,
        }
        axios
            .post(`sales/getarchivedeventhostdetaillist`,qs.stringify(payload))
            .then(async response => {
                // Sort the response data to ensure the main host is first
                const sortedHosts = response.data.eventHostDetailList.sort((a, b) => {
                    if (a.type === "main") return -1
                    if (b.type === "main") return 1
                    return 0;
                })

                this.setState({
                    loading:false,
                    seatOverviewModal:true,
                    eventHostDetailList:sortedHosts,
                    totalSeatNumber:response.data.totalSeatNumber,
                    totalPublicSeat:response.data.totalPublicSeat,
                })
            })
            .catch(error => {
                if(error.response){
                    
                }
                alert("Get archive event host detail list error\n" + error)
                this.setState({loading: false})
            })
    }

    getArchivedOfflineEventSeatLog=()=>{
        this.setState({loading:true})
        const payload = {
            id:this.state.currentOfflineEvent.id,
            itemPerPage:this.state.logPageItem,
            page:this.state.logPage,
        }
        axios
            .post('sales/getarchivedofflineeventseatlog',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                for(let log of response.data.currentLogList){
                    if(log.createDate){
                        let rawDateSplit=log.createDate.split("T")
                        let dateSplit=rawDateSplit[0].split("-")
                        let timeSplit=rawDateSplit[1].split(":")
                        log.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                    }
                }

                this.setState({
                    loading:false,
                    archivedSeatLogModal:true,
                    currentLogList:response.data.currentLogList,
                    totalLogPage:response.data.totalLogPage,
                    logPage:response.data.logPage,
                    allLogCount:response.data.allLogCount,
                })
            })
            .catch(error => {
                if(error.response){

                }
                // General error message directly from error object
                alert('Get offline event seat log error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    testWhatsappGroupMessage=()=>{
        //validate
        if(this.state.testWhatsappPhoneNumber==null||this.state.testWhatsappPhoneNumber==""){
            alert("Please fill in the target phone number")
            return
        }

        this.setState({loading:true})
        let payload = {
            target:this.state.testWhatsappPhoneNumber,
        }
        if(this.state.newOfflineEventModal==true){
            payload.message=this.state.createWhatsappGroupMessage
        }else if(this.state.editOfflineEventModal==true){
            payload.message=this.state.editWhatsappGroupMessage
        }else if(this.sessionMode=="previous"){
            payload.message=this.state.currentOfflineEvent.whatsappGroupMessage
        }
        axios
            .post('sales/testwhatsappgroupmessage',qs.stringify(payload),{timeout:40000})
            .then(async response =>{
                alert("Whatsapp message sent")
                this.setState({
                    loading:false,
                    testWhatsappMessageModal:false,
                })
            })
            .catch(error => {
                if(error.response){

                }
                // General error message directly from error object
                alert('Test Whatsapp test message error:\n' + error.message)
                this.setState({ loading: false })
            })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }

                <Modal style={{maxWidth:600}} isOpen={this.state.testWhatsappMessageModal} toggle={()=>this.setState({testWhatsappMessageModal:false})}>
                    <div style={{display:"flex",flexDirection:"column",gap:10,padding:"30px 20px",width:"100%",justifyContent:"center"}}>
                        <div>
                            <div>Target Phone Number</div>
                            <Input placeholder="0115555555" style={{width:"100%"}} value={this.state.testWhatsappPhoneNumber} onChange={(e)=>{
                                this.setState({testWhatsappPhoneNumber:e.target.value.replace(/\D/g,"")})
                            }} />
                        </div>
                        <div>
                            <div>Whatsapp Group Message</div>
                            <textarea rows="14" readOnly={this.sessionMode=="previous"} style={{borderRadius:5,border:"1px solid lightgrey",width:"100%",padding:5}}
                                value={
                                    this.state.newOfflineEventModal==true?
                                        this.state.createWhatsappGroupMessage
                                        :
                                    this.state.editOfflineEventModal==true?
                                        this.state.editWhatsappGroupMessage
                                        :
                                    this.sessionMode=="previous"&&this.state.currentOfflineEvent!=null?
                                        this.state.currentOfflineEvent.whatsappGroupMessage
                                        :
                                        null
                                } 
                                onChange={(e)=>{
                                    if(this.state.newOfflineEventModal==true){
                                        this.setState({createWhatsappGroupMessage:e.target.value})
                                    }else if(this.state.editOfflineEventModal==true){
                                        this.setState({editWhatsappGroupMessage:e.target.value})
                                    }
                                }}
                            />
                        </div>
                        <div>
                            What you see is exactly what client get later.
                        </div>
                        <div style={{display:"flex",flexDirection:"row",gap:10,marginTop:20}}>
                            <Button color="primary" style={{flex:1}} onClick={()=>{
                                this.testWhatsappGroupMessage()
                            }}>
                                Send
                            </Button>
                            <Button style={{flex:1,backgroundColor:"salmon",border:"none"}} onClick={()=>{
                                this.setState({testWhatsappMessageModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.paymentBankModal} toggle={()=>this.setState({paymentBankModal:false,displayBankDetail:null})}>
                    <div style={{display:"flex",flexDirection:"column",gap:5,padding:"30px 20px",width:"100%",justifyContent:"center"}}>
                        {
                        this.state.displayBankDetail!=null?
                            this.state.displayBankDetail.startsWith("data:image")?
                                <img src={this.state.displayBankDetail} style={{width:"100%"}} />
                                :
                                this.state.displayBankDetail.split("\n").map((line)=>{return(<div>{line}</div>)})
                            :
                            null
                        }
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.chooseNonMemberReferrerModal} toggle={()=>this.setState({chooseNonMemberReferrerModal:false})}>
                    <div style={{display:"flex",flexDirection:"column",gap:5,padding:20,width:"100%",justifyContent:"center"}}>
                        <div style={{display:"flex",justifyContent:"center"}}>Choose Non Member Referrer</div>
                        {
                        GLOBAL.checkPermission("9~FlvNd9")? //higher get company agent list
                            <div style={{display:"flex",flexDirection:"row",gap:5,marginTop:5,marginBottom:5}}>
                                <Input placeholder="Agent Username" 
                                    value={this.state.searchNonMemberReferrer} 
                                    onChange={(e)=>{this.setState({searchNonMemberReferrer:e.target.value})}}
                                    onKeyDown={(e)=>{
                                        if(e.key=='Enter'){
                                            this.getHigherCompanyAgentList()
                                        }
                                    }}
                                />
                                <Button color="primary" style={{}} onClick={()=>{
                                    this.getHigherCompanyAgentList()
                                }}>
                                    Search
                                </Button>
                            </div>
                            :
                            null
                        }
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",flexFlow:"row-wrap",gap:10,marginBottom:5}}>
                            <Button color={this.state.mode==="agent"?"primary":"secondary"} style={{padding:"3px 32px"}} onClick={()=>{
                                this.getCompanyAgentList()
                            }}>
                                Agent
                            </Button>
                            <Button color={this.state.mode==="member"?"primary":"secondary"} style={{padding:"3px 32px"}} onClick={()=>{
                                this.getCompanyAgentMemberList()
                            }}>
                                Member
                            </Button>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%"}}>
                            <div style={{padding:10,border:"1px solid lightgrey",borderRadius:10,cursor:"pointer",gap:5}} onClick={()=>{
                                this.setState({selectedNonMemberReferrer:null,chooseNonMemberReferrerModal:false})
                            }}>
                                [No Referrer]
                            </div>
                        </div>
                            {
                            this.state.mode==="agent" && (
                                this.state.agentList.map((agent)=>{
                                    return(
                                        <div style={{display:"flex",padding:10,border:"1px solid lightgrey",borderRadius:10,cursor:"pointer",justifyContent:"space-between",fontSize:14}} onClick={()=>{
                                            this.setState({selectedNonMemberReferrer:agent,chooseNonMemberReferrerModal:false})
                                        }}>
                                            {agent.phone} {agent.name} 
                                            <div style={{justifyItems:"end",fontSize:12,whiteSpace:"nowrap"}}>
                                            {agent.tier?" ["+agent.tier.name+"]":null}
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                                {
                                this.state.mode==="member" && (
                                <div>
                                    <div style={{display:"flex",flexDirection:"row",gap:5,marginTop:15,marginBottom:15}}>
                                        <Input placeholder="Member Phone Number or Name" 
                                            value={this.state.searchTerm} 
                                            onChange={(e)=>{this.setState({searchTerm:e.target.value})}}
                                            onKeyDown={(e)=>{
                                                if(e.keyCode==13){
                                                    e.preventDefault()
                                                    this.getCompanyAgentMemberList()
                                                }
                                        }}></Input>
                                        <Button color="primary" style={{}} onClick={()=>{
                                            this.getCompanyAgentMemberList()
                                        }}>
                                            Search
                                        </Button>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%",justifyContent:"center",marginBottom:20}}>
                                        {this.state.memberList !==""?
                                            this.state.memberList.map((member)=>{
                                            return(
                                                <div style={{display:"flex",padding:10,border:"1px solid lightgrey",borderRadius:10,cursor:"pointer",justifyContent:"space-between",gap:5}} onClick={()=>{
                                                    this.setState({selectedNonMemberReferrer:member,chooseNonMemberReferrerModal:false})
                                                }}>
                                                    {member.username} {member.name}
                                                </div>
                                                )
                                            })
                                             : 
                                            <div style={{ display: "flex", justifyContent: "center", padding:10}}>
                                                No members found.
                                            </div>
                                        }
                                    </div>
                                        <ReactPaginate
                                        pageCount={this.state.totalPage}
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        breakLabel={"..."}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        disabledClassName={"disabled"}
                                        forcePage={this.state.page}
                                        onPageChange={async ({selected}) => {
                                            this.setState({page:selected},()=>{
                                                this.getCompanyAgentMemberList()
                                            })
                                        }}/>
                                </div>
                                )}
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.selectMemberModal} toggle={()=>this.setState({selectMemberModal:false})}>
                    {
                    window.innerWidth > 600 ?
                        <div style={{padding:20}}>
                            <div style={{paddingBottom:20}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <Input value={this.state.searchField}
                                        placeholder='Enter phone number or name'
                                        style={{width:"100%"}}
                                        onChange={(e)=>{this.setState({searchField:e.target.value})}}
                                        onKeyDown={(e)=>{
                                            if(e.keyCode==13){
                                                e.preventDefault()
                                                this.getAllMember()
                                            }
                                    }}></Input>
                                    <Button style={{marginLeft:"5px"}} onClick={()=>this.setState({selectMemberModal:false})}>Close</Button>
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"0px 0px 20px 0px"}}>
                                <div style={{display:"flex",flexDirection:"row",width:"100%",paddingBottom:3,justifyContent:"space-between"}}>
                                    <div>No Referrer</div>
                                    <div>
                                        <Button style={{width:"100%"}}
                                            color='primary'
                                            onClick={(e)=>
                                                this.setState({selectMemberModal:false,selectedNonMemberReferrer:null})}>
                                            Select
                                        </Button>
                                    </div>
                                </div>
                                <table>
                                    <thead>
                                        <th>Username</th>
                                        <th>Name</th>
                                        <th>Join Date</th>
                                        <th></th>
                                    </thead>
                                   
                                    {
                                    this.state.memberList.map((item)=>{
                                        
                                        return(
                                            <tbody style={{width:"100%"}}>
                                                <td style={{paddingRight:30}}>{item.username}</td>
                                                <td style={{paddingRight:30}}>{item.name}</td>
                                                <td style={{paddingRight:30}}>{item.date_joined}</td>
                                                <td>
                                                    <Button value={item.id} 
                                                        style={{width:"100%"}} 
                                                        color='secondary'
                                                        onClick={(e)=>
                                                            this.setState({selectMemberModal:false,selectedNonMemberReferrer:item})}>
                                                        Select
                                                    </Button>
                                                </td>
                                            </tbody>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                            <ReactPaginate
                                pageCount={this.state.totalPage}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.setState({page:selected},()=>{
                                        this.getAllMember()
                                    })
                                }}/>
                        </div>
                    :
                    <div style={{padding:20}}>
                        <div style={{paddingBottom:20}}>
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <Input value={this.state.searchField}
                                    placeholder='Enter phone number or name'
                                    style={{width:"100%"}}
                                    onChange={(e)=>{this.setState({searchField:e.target.value})}}
                                    onKeyDown={(e)=>{
                                        if(e.keyCode==13){
                                            e.preventDefault()
                                            this.getAllMember()
                                        }
                                }}></Input>
                                <Button style={{marginLeft:"5px"}} onClick={()=>this.setState({selectMemberModal:false})}>Close</Button>
                            </div>
                        </div>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"0px 0px 20px 0px"}}>
                            <div style={{display:"flex",flexDirection:"row",width:"100%",paddingBottom:3,justifyContent:"space-between",fontSize:"10px",borderCollapse:"unset"}}>
                                <div>No Referrer</div>
                                <div>
                                    <Button style={{width:"100%",fontSize:"10px"}}
                                        color='primary'
                                        onClick={(e)=>
                                            this.setState({selectMemberModal:false,selectedNonMemberReferrer:null})}>
                                        Select
                                    </Button>
                                </div>
                            </div>
                            <table style={{fontSize:"10px",borderCollapse:"unset"}}>
                                <thead>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Join Date</th>
                                    <th></th>
                                </thead>

                                <tbody style={{width:"100%"}}>
                                    <td style={{paddingRight:30}}>No Referrer</td>
                                    <td style={{paddingRight:30}}></td>
                                    <td style={{paddingRight:30}}></td>
                                    <td>
                                        <Button style={{width:"100%",fontSize:"10px"}}
                                            color='primary'
                                            onClick={(e)=>
                                                this.setState({selectMemberModal:false,selectedNonMemberReferrer:null})}>
                                            Select
                                        </Button>
                                    </td>
                                </tbody>

                                {
                                this.state.memberList.map((item)=>{
                                    return(
                                        <tbody style={{width:"100%"}}>
                                            <td style={{paddingRight:30}}>
                                            {item.username}
                                            </td>
                                            <td style={{paddingRight:30}}>{item.name}</td>
                                            <td style={{paddingRight:30}}>{item.date_joined}</td>
                                            <td>
                                                <Button value={item.id} 
                                                    style={{width:"100%",fontSize:"10px"}} 
                                                    color='secondary'
                                                    onClick={(e)=>
                                                        this.setState({selectMemberModal:false,selectedNonMemberReferrer:item})}>
                                                    Select
                                                </Button>
                                            </td>
                                        </tbody>
                                    )
                                })
                                }
                            </table>
                        </div>
                        <ReactPaginate
                                pageCount={this.state.totalPage}
                                previousLabel={'<'}
                                nextLabel={'>'}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                disabledClassName={"disabled"}
                                forcePage={this.state.page}
                                onPageChange={async ({selected}) => {
                                    this.setState({page:selected},()=>{
                                        this.getAllMember()
                                    })
                                }}/>
                    </div>
                    }
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.verifyModal} toggle={()=>this.setState({verifyModal:false})}>
                    <div style={{display:"flex",flexDirection:"column",gap:5,padding:"30px 20px",width:"100%",justifyContent:"center"}}>
                        <div style={{display:"flex",justifyContent:"center"}}>Verify By:</div>
                        <div style={{display:"flex",justifyContent:"center"}}>
                            <Button color="light" style={{backgroundColor:"green",color:"white",border:"1px solid grey",marginRight:5}} onClick={()=>this.sendVerifyLink(this.state.nonMemID,1)}>
                                WhatsApp
                            </Button>
                            <Button color="light" style={{backgroundColor:"grey",color:"white",border:"1px solid grey",marginLeft:5}} onClick={()=>this.sendVerifyLink(this.state.nonMemID,2)}>
                                SMS
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.paymentBankDetailsModal} toggle={()=>this.setState({paymentBankDetailsModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:16,fontWeight:"bold"}}>Enter Payment Bank Details for the Host</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%",marginTop:10}}>
                            <div>
                                {
                                this.state.selectionBuffer2!=null?
                                    this.state.selectionBuffer2.contactPerson.username==this.state.selectionBuffer2.contactPerson.name?
                                        "["+this.state.selectionBuffer2.company.name+"] "+
                                        this.state.selectionBuffer2.contactPerson.name
                                        :
                                        "["+this.state.selectionBuffer2.company.name+"] "+
                                        this.state.selectionBuffer2.contactPerson.username+" "+
                                        this.state.selectionBuffer2.contactPerson.name
                                    :
                                    null
                                }
                            </div>
                            {
                            this.state.selectionBuffer2!=null&&this.state.selectionBuffer2.company.boldpayUsername!=null&&this.state.selectionBuffer2.company.boldpayAPIKey?
                                null
                                :
                                <div style={{display:"flex",flexDirection:"row",gap:5}}>
                                    <Button color={this.state.inputPaymentType=="bank"?"primary":"secondary"} onClick={()=>{
                                        this.setState({inputPaymentType:"bank"})
                                    }}>
                                        Bank
                                    </Button>
                                    <Button color={this.state.inputPaymentType=="image"?"primary":"secondary"} onClick={()=>{
                                        this.setState({inputPaymentType:"image"})
                                    }}>
                                        Image (QR,etc)
                                    </Button>
                                </div>
                            }
                            {
                            this.state.selectionBuffer2!=null&&this.state.selectionBuffer2.company.boldpayUsername!=null&&this.state.selectionBuffer2.company.boldpayAPIKey?
                                <div>
                                    <div>Boldpay Integrated:</div>
                                    <div>Boldpay Username: {this.state.selectionBuffer2.company.boldpayUsername}</div>
                                    <div>Boldpay API Key: {this.state.selectionBuffer2.company.boldpayAPIKey}</div>
                                </div>
                                :
                                this.state.inputPaymentType=="bank"?
                                    <div>
                                        <div>Payment Bank Details:</div>
                                        <textarea rows={5} style={{width:"100%",padding:"5px 10px"}} onChange={(e)=>{
                                            this.setState({paymentBankDetails:e.target.value})
                                        }} />
                                    </div>
                                    :
                                this.state.inputPaymentType=="image"?
                                    <div>
                                        <div>Upload Image:</div>
                                        <Input type="file" accept="image/png, image/jpeg" onChange={(e)=>{
                                            const file = e.target.files[0]
                                            if (file) {
                                                const reader = new FileReader()
                                                reader.onloadend = () => {
                                                    this.setState({paymentBankDetails:reader.result})
                                                };
                                                reader.readAsDataURL(file)
                                            }
                                        }}/>
                                    </div>
                                    :
                                    null  
                            }
                        </div>
                        <div style={{width:"100%",marginTop:10,display:"flex",flexDirection:"column",gap:5}}>
                            <Button color="primary" style={{width:"100%"}} onClick={()=>{
                                //validate
                                if(this.state.selectionBuffer2.company.boldpayUsername==null||this.state.selectionBuffer2.company.boldpayAPIKey==null){
                                    if(this.state.paymentBankDetails==null||this.state.paymentBankDetails.trim()==""){
                                        alert("Please enter payment bank details or payment image")
                                        return
                                    }
                                }

                                let newHost={
                                    company:this.state.selectionBuffer2.company,
                                    contactPerson:this.state.selectionBuffer2.contactPerson,
                                    paymentBankDetails:this.state.paymentBankDetails,
                                }
                                if(this.state.selectionMode=="main"){
                                    this.setState({selectedMainHost:newHost})
                                }else if(this.state.selectionMode=="other"){
                                    let otherHostClone=this.state.selectedOtherHost
                                    if(otherHostClone==null){
                                        otherHostClone=[]
                                    }
                                    otherHostClone.push(newHost)
                                    this.setState({selectedOtherHost:otherHostClone})
                                }
                                this.setState({
                                    paymentBankDetailsModal:false,
                                    selectContactPersonModal:false,
                                    selectHostCompanyModal:false,
                                })
                            }}>
                                Add
                            </Button>
                            <Button color="light" style={{backgroundColor:"salmon",border:"none",color:"white",width:"100%"}} onClick={()=>{
                                this.setState({paymentBankDetailsModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.selectContactPersonModal} toggle={()=>this.setState({selectContactPersonModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:16,fontWeight:"bold"}}>Select Contact Person (Event Host)</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%",marginTop:10}}>
                            {
                            this.state.eventHostList.map((eventHost)=>{
                                return(
                                    <Button color="light" style={{border:"1px solid lightgrey",display:"flex",
                                        flexDirection:"row",alignItems:"center",gap:5}} 
                                        onClick={()=>{
                                            this.setState({
                                                selectionBuffer2:{
                                                    company:this.state.selectionBuffer,
                                                    contactPerson:eventHost,
                                                },
                                                paymentBankDetailsModal:true
                                            })
                                    }}>
                                        {
                                        eventHost.username==eventHost.name?
                                            eventHost.name
                                            :
                                            eventHost.username+" "+eventHost.name
                                        }
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{width:"100%",marginTop:10,}}>
                            <Button color="light" style={{backgroundColor:"salmon",border:"none",color:"white",width:"100%"}} onClick={()=>{
                                this.setState({selectContactPersonModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.selectHostCompanyModal} toggle={()=>this.setState({selectHostCompanyModal:false})}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:16,fontWeight:"bold"}}>Select Host Company</div>
                        <div style={{display:"flex",flexDirection:"column",gap:5,width:"100%",marginTop:10}}>
                            {/* <Button color="light" style={{border:"1px solid lightgrey",display:"flex",color:"grey",backgroundColor:"#e6ebea",
                                flexDirection:"row",alignItems:"center",gap:5}} 
                                onClick={()=>{
                                    this.setState({
                                        selectedMainHost:null,
                                        selectHostCompanyModal:false
                                    })
                                }
                            }>
                                None
                            </Button> */}
                            {
                            this.state.hostCompanyList.map((hostCompany)=>{
                                //if the company is already selected, do not show
                                if(this.state.selectedMainHost!=null){
                                    if(this.state.selectedMainHost.company!=null&&this.state.selectedMainHost.company.id==hostCompany.id){
                                        return
                                    }
                                }
                                if(this.state.selectedOtherHost!=null){
                                    for(let host of this.state.selectedOtherHost){
                                        if(host.company!=null&&host.company.id==hostCompany.id){
                                            return
                                        }
                                    }
                                }
                                return(
                                    <Button color="light" style={{border:"1px solid lightgrey",display:"flex",
                                        flexDirection:"row",alignItems:"center",gap:5}} 
                                        onClick={()=>{
                                            this.setState({
                                                selectionBuffer:hostCompany,
                                            },()=>{
                                                if (this.state.selectionBuffer.name=="总部") {
                                                    let newHost={
                                                        company:this.state.selectionBuffer,
                                                        contactPerson:null,
                                                        paymentBankDetails:null,
                                                    }
                                                    if(this.state.selectionMode=="main"){
                                                        this.setState({selectedMainHost:newHost})
                                                    }else if(this.state.selectionMode=="other"){
                                                        let otherHostClone=this.state.selectedOtherHost
                                                        if(otherHostClone==null){
                                                            otherHostClone=[]
                                                        }
                                                        otherHostClone.push(newHost)
                                                        this.setState({selectedOtherHost:otherHostClone})
                                                    }
                                                    this.setState({
                                                        selectHostCompanyModal:false,
                                                    })
                                                }else{
                                                    this.getSelectedCompanyEventHost()
                                                }
                                            })
                                        }
                                    }>
                                        {hostCompany.name}
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <div style={{width:"100%",marginTop:10,}}>
                            <Button color="light" style={{backgroundColor:"salmon",border:"none",color:"white",width:"100%"}} onClick={()=>{
                                this.setState({selectHostCompanyModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.ticketStatusModal} toggle={()=>this.setState({ticketStatusModal:false})} autoFocus={false}>
                    <div style={{padding:20}}>
                        <div style={{fontSize:20,fontWeight:"bold",marginBottom:15,textAlign:"center",width:"100%"}}>
                            {this.state.memberDetail}
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            {
                            this.state.ticketStatus=="success"?
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <FaCheckCircle style={{fontSize:120,color:"#abd64f"}} />
                                    <div style={{marginTop:15}}>Ticket check success. Attendance marked.</div>
                                </div>
                                :
                            this.state.ticketStatus=="notFound"?
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <FaTimesCircle style={{fontSize:120,color:"salmon"}} />
                                    <div style={{marginTop:15}}>Ticket not found.</div>
                                </div>
                                :
                            this.state.ticketStatus=="already"?
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <FaTimesCircle style={{fontSize:120,color:"salmon"}} />
                                    <div style={{marginTop:15}}>This ticket is already attend for today.</div>
                                </div>
                                :
                                null
                            }
                            <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} autoFocus={true} onClick={()=>{
                                this.setState({ticketStatusModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{}} isOpen={this.state.manualMarkAttendanceModal} toggle={()=>this.setState({manualMarkAttendanceModal:false})}>
                    {
                    this.state.selectedParticipant!=null?
                        <div style={{padding:20}}>
                            <div style={{fontSize:20,fontWeight:"bold"}}>Manual Mark Attendance</div>
                            <div style={{width:"100%",textAlign:"center"}}>
                                {
                                this.state.selectedParticipant.user!=null?
                                    this.state.selectedParticipant.user.username==this.state.selectedParticipant.user.name?
                                        this.state.selectedParticipant.user.name
                                        :
                                        this.state.selectedParticipant.user.username+" "+this.state.selectedParticipant.user.name
                                    :
                                    this.state.selectedParticipant.phone+" "+this.state.selectedParticipant.name
                                }
                            </div>
                            <div style={{width:"100%",display:"flex",flexDirection:"row",gap:10,marginTop:10,justifyContent:"center"}}>
                                {this.generatePaticipantAttendance()}
                            </div>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <Button color="primary" style={{marginTop:20,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                    this.setState({manualMarkAttendanceModal:false,selectedParticipant:null})
                                }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                        :
                        null
                    }
                </Modal>

                <Modal style={{}} isOpen={this.state.ticketQRCodeModal} toggle={()=>this.setState({ticketQRCodeModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{fontSize:20,fontWeight:"bold"}}>Ticket QR Code</div>
                        <div style={{width:"100%",textAlign:"center"}}>
                            {this.state.ticketQRCodeUserDetail}

                        </div>
                        {
                        this.state.ticketQRCode&&
                            <QRCode
                                id="QR"
                                value={this.state.ticketQRCode}
                                level="M"
                                renderAs="svg"
                                size={256}
                                imageSettings={{
                                    height:70,
                                    width:70,
                                }}
                                style={{marginTop:10}}
                            />
                        }
                        <div style={{display:"flex",flexDirection:"row",gap:5,width:"100%",marginTop:20}}>
                            <Button color="success" style={{flex:1}} onClick={() => {
                                let svg = document.getElementById("QR")
                                let serializedSVG = new XMLSerializer().serializeToString(svg)
                                let base64Data = window.btoa(serializedSVG)
                                let imageUrl = "data:image/svg+xml;base64," + base64Data

                                let a = document.createElement("a")
                                a.setAttribute("href", imageUrl)
                                a.setAttribute("download", this.state.ticketQRCode.replaceAll(" ", "_") + "_qrcode.svg")
                                a.dispatchEvent(new MouseEvent('click', { view: window, bubbles: false, cancelable: true }))
                            }}>Download</Button>
                            <Button style={{backgroundColor:"salmon",border:"none",color:"white"}} onClick={()=>{
                                this.setState({ticketQRCodeModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.scanQRCodeModal} toggle={() => this.setState({ scanQRCodeModal: false })} autoFocus={false}>
                    {
                    this.state.currentOfflineEvent!=null?
                        <div style={{ padding: 20, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <div style={{marginBottom:10,display:"flex",flexDirection:"row",width:"100%",alignItems:"center"}}>
                                <Button color={this.state.openQRCamera==true?"secondary":"primary"} onClick={()=>{
                                    this.setState({openQRCamera:false})
                                }}>
                                    Scanner
                                </Button>
                                <Button color={this.state.openQRCamera==true?"primary":"secondary"} style={{marginLeft:10}} onClick={()=>{
                                    this.setState({openQRCamera:true})
                                }}>
                                    Camera
                                </Button>
                                <div style={{marginLeft:20}}>
                                    Attendance for: {this.state.currentOfflineEvent.title+" "+new Date().getDate()+"th"}
                                </div>
                            </div>
                            {
                            this.state.scanQRCodeModal==true?
                                this.state.openQRCamera==true?
                                    <div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
                                        <QrReader
                                            delay={500}
                                            onError={this.handleError}
                                            onScan={this.handleScan}
                                            style={{ width: '100%' }}
                                        />
                                        <div>{this.state.scanInfo}</div>
                                    </div>
                                    :
                                    <div style={{width:"100%"}}>
                                        <Input value={this.state.scannerData} id={"ScanBarcode"} type="text" autoFocus={true} onChange={(e)=>{
                                            this.setState({scannerData:e.target.value},()=>{
                                                //wait for Xms to start getting the item
                                                if(this.runningBarcodeScanner==false){
                                                    this.runningBarcodeScanner=true
                                                    setTimeout(()=>{
                                                        let found=false
                                                        //find the user from server
                                                        this.handleScan(this.state.scannerData)
                                                        this.runningBarcodeScanner=false
                                                    }, this.scanBarcodeCooldown)
                                                }
                                            })
                                        }}  />
                                    </div>
                                : null
                            }
                        </div>
                        :
                        null
                    }
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.removeParticipantModal} toggle={()=>this.setState({removeParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div>Remove {this.eventName} Participant</div>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <Input type="text" placeholder="Phone number (username)" value={this.state.removeParticipantUsername} onChange={(e)=>{
                                this.setState({removeParticipantUsername:e.target.value})
                            }} />
                            <Button color="primary" style={{marginLeft:10,paddingLeft:50,paddingRight:50}} onClick={()=>{
                                this.removeOfflineEventParticipant()
                            }}>
                                Remove
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.newOfflineEventModal} toggle={()=>this.setState({newOfflineEventModal:false})}>
                    <div style={{padding:20}}>
                        <div>Create new {this.eventName}</div>

                        <Form onSubmit={(e)=>{
                            if (this.state.newFranchiseVenueLat && this.state.newFranchiseVenueLng) {
                                this.createNewOfflineEventFunc()
                            }else{
                                alert("Please plot out the venue location");
                            }
                            e.preventDefault()
                        }}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div style={{display:"flex",flexDirection:"row",width:"100%",gap:10}}>
                                    <div style={{display:"flex",flexDirection:"column",flex:1}}>
                                        <div>
                                            Title:
                                        </div>
                                        <Input type="text" required style={{}} value={this.state.newFranchiseTitle} onChange={(e)=>{
                                            this.setState({newFranchiseTitle:e.target.value})
                                        }} />
                                        <div>
                                            Start Date:
                                        </div>
                                        <Input type="datetime-local" required style={{}} value={this.state.newFranchiseStartDate} onChange={(e)=>{
                                            this.setState({newFranchiseStartDate:e.target.value})
                                        }} />
                                        <div>
                                            End Date:
                                        </div>
                                        <Input type="datetime-local" required style={{}} value={this.state.newFranchiseEndDate} onChange={(e)=>{
                                            this.setState({newFranchiseEndDate:e.target.value})
                                        }} />
                                        <div>
                                            Venue:
                                        </div>
                                        <Input type="text" required style={{}} value={this.state.newFranchiseVenue} onChange={(e)=>{
                                            this.setState({newFranchiseVenue:e.target.value})
                                        }} />
                                        <div>
                                            Available Seat:
                                        </div>
                                        <Input type="text" required style={{}} value={this.state.newFranchiseSeat} onChange={(e)=>{
                                            this.setState({newFranchiseSeat:e.target.value.replace(/\D/g,"")})
                                        }} />
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",flex:1}}>
                                        <div style={{display:"flex",flexDirection:"row",gap:10,alignItems:"center"}}>
                                            <div>Main Host: </div>
                                            <Button color="primary" style={{padding:"2px 10px",fontSize:12,marginTop:5}} onClick={()=>{
                                                this.setState({selectionMode:"main"},()=>{this.getHostCompanyList()})
                                            }}>
                                                Select
                                            </Button>
                                        </div>
                                        {
                                        this.state.selectedMainHost!=null?
                                            <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",gap:5,
                                                display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                <div style={{fontSize:13}}>
                                                    {
                                                    this.state.selectedMainHost.contactPerson!=null?
                                                        this.state.selectedMainHost.contactPerson.username==this.state.selectedMainHost.contactPerson.name?
                                                            "["+this.state.selectedMainHost.company.name+"] "+
                                                            this.state.selectedMainHost.contactPerson.name
                                                            :
                                                            "["+this.state.selectedMainHost.company.name+"] "+
                                                            this.state.selectedMainHost.contactPerson.username+" "+
                                                            this.state.selectedMainHost.contactPerson.name
                                                        :
                                                        "["+this.state.selectedMainHost.company.name+"]"
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                    this.state.selectedMainHost.paymentBankDetails!=null?
                                                        <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                            this.setState({
                                                                paymentBankModal:true,
                                                                displayBankDetail:this.state.selectedMainHost.paymentBankDetails
                                                            })
                                                        }} />
                                                        :
                                                        this.state.selectedMainHost.company.name=="总部"?
                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                E
                                                            </div>
                                                            :
                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                B
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div style={{fontSize:13,color:"grey"}}>None</div>
                                        }
                                        
                                        <div style={{display:"flex",flexDirection:"row",gap:10,alignItems:"center"}}>
                                            <div>Other Host: </div>
                                            <Button color="primary" style={{padding:"2px 10px",fontSize:12,marginTop:5}} onClick={()=>{
                                                this.setState({selectionMode:"other"},()=>{this.getHostCompanyList()})
                                            }}>
                                                Select
                                            </Button>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:2}}>
                                            {
                                            this.state.selectedOtherHost!=null&&this.state.selectedOtherHost.length>0?
                                                this.state.selectedOtherHost.map((host,index)=>{
                                                    return(
                                                        <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",fontSize:13,
                                                            display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                                            <div>
                                                                {
                                                                host.contactPerson!=null?
                                                                    host.contactPerson.username==host.contactPerson.name?
                                                                        "["+host.company.name+"] "+
                                                                        host.contactPerson.name
                                                                        :
                                                                        "["+host.company.name+"] "+
                                                                        host.contactPerson.username+" "+
                                                                        host.contactPerson.name
                                                                    :
                                                                    "["+host.company.name+"]"
                                                                }
                                                            </div>
                                                            <div style={{display:"flex",flexDirection:"row",gap:5,flexWrap:"nowrap"}}>
                                                                {
                                                                host.paymentBankDetails!=null?
                                                                    <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                                        this.setState({
                                                                            paymentBankModal:true,
                                                                            displayBankDetail:host.paymentBankDetails
                                                                        })
                                                                    }} />
                                                                    :
                                                                    host.company.name=="总部"?
                                                                        <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                            E
                                                                        </div>
                                                                        :
                                                                        <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                            B
                                                                        </div>
                                                                }
                                                                <div style={{cursor:"pointer",fontWeight:"bold",}}
                                                                    onClick={()=>{
                                                                        let otherHostClone=this.state.selectedOtherHost
                                                                        otherHostClone.splice(index,1)
                                                                        this.setState({selectedOtherHost:otherHostClone})
                                                                    }}
                                                                >
                                                                    X
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{fontSize:13,color:"grey"}}>None</div>
                                            }
                                        </div>
                                        
                                        <div>Price: </div>
                                        <Input required value={this.state.createPrice} style={{width:"100%"}} onChange={(e)=>{
                                            this.setState({createPrice:e.target.value.replace(/\D/g,"")})
                                        }} />

                                        {
                                        this.currentType=="systemonline"?
                                            <div style={{width:"100%",display:"flex",flexDirection:"column",marginTop:5}}>
                                                <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
                                                    <div>Whatsapp Group Message</div>
                                                    <Button color="primary" style={{padding:"2px 5px",fontSize:12,}} onClick={()=>{
                                                        this.setState({testWhatsappMessageModal:true})
                                                    }}>
                                                        Test
                                                    </Button>
                                                </div>
                                                <textarea required rows="5" value={this.state.createWhatsappGroupMessage} style={{width:"100%"}} onChange={(e)=>{
                                                    this.setState({createWhatsappGroupMessage:e.target.value})
                                                }} />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div style={{marginTop:10}}>
                                    <GoogleMap
                                        setLngLat={(lat, lng) => { this.setState({ newFranchiseVenueLat: lat, newFranchiseVenueLng: lng }) }}
                                        setAddress={(address) => { this.setState({ newFranchiseVenueAddress: address }) }}
                                        address={this.state.newFranchiseVenueAddress}
                                        position={{ "lat": this.state.newFranchiseVenueLat, "lng": this.state.newFranchiseVenueLng }}
                                    />
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                <Input type="submit" value="Create" color="primary" />
                                <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                    this.setState({newOfflineEventModal:false})
                                    }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.editOfflineEventModal} toggle={()=>this.setState({editOfflineEventModal:false})}>
                    <div style={{padding:20}}>
                        <div>Edit {this.eventName}</div>
                        <Form onSubmit={(e)=>{
                            this.editOfflineEventFunction()
                            e.preventDefault()
                        }}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div style={{display:"flex",flexDirection:"row",gap:10,width:"100%"}}>
                                    <div style={{display:"flex",flexDirection:"column",flex:1}}>
                                        <div>
                                            Title:
                                        </div>
                                        <Input type="text" required style={{}} value={this.state.editOfflineEventTitle} onChange={(e)=>{
                                            this.setState({editOfflineEventTitle:e.target.value})
                                        }} />
                                        <div>
                                            Start Date:
                                        </div>
                                        <Input type="datetime-local" required style={{}} value={this.state.editOfflineEventStartDate} onChange={(e)=>{
                                            this.setState({editOfflineEventStartDate:e.target.value})
                                        }} />
                                        <div>
                                            End Date:
                                        </div>
                                        <Input type="datetime-local" required style={{}} value={this.state.editOfflineEventEndDate} onChange={(e)=>{
                                            this.setState({editOfflineEventEndDate:e.target.value})
                                        }} />
                                        <div>
                                            Venue:
                                        </div>
                                        <Input type="text" required style={{}} value={this.state.editOfflineEventVenue} onChange={(e)=>{
                                            this.setState({editOfflineEventVenue:e.target.value})
                                        }} />
                                        <div>
                                            Available Seat:
                                        </div>
                                        {
                                        GLOBAL.checkPermission("Em2?ZS3-")?
                                            <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.getEventHostDetailList()}}>
                                                Manage Seat
                                            </Button>
                                            :
                                            null
                                        }
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column",flex:1}}>                                        
                                        <div style={{display:"flex",flexDirection:"row",gap:10,alignItems:"center"}}>
                                            <div>Main Host: </div>
                                            <Button color="primary" style={{padding:"2px 10px",fontSize:12,marginTop:5}} onClick={()=>{
                                                this.setState({selectionMode:"main"},()=>{this.getHostCompanyList()})
                                            }}>
                                                Select
                                            </Button>
                                        </div>
                                        {
                                        this.state.selectedMainHost!=null?
                                            <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",gap:5,
                                                display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                <div style={{fontSize:13}}>
                                                    {
                                                    this.state.selectedMainHost.contactPerson!=null?
                                                        this.state.selectedMainHost.contactPerson.username==this.state.selectedMainHost.contactPerson.name?
                                                            "["+this.state.selectedMainHost.company.name+"] "+
                                                            this.state.selectedMainHost.contactPerson.name
                                                            :
                                                            "["+this.state.selectedMainHost.company.name+"] "+
                                                            this.state.selectedMainHost.contactPerson.username+" "+
                                                            this.state.selectedMainHost.contactPerson.name
                                                        :
                                                        "["+this.state.selectedMainHost.company.name+"]"
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                    this.state.selectedMainHost.paymentBankDetails!=null?
                                                        <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                            this.setState({
                                                                paymentBankModal:true,
                                                                displayBankDetail:this.state.selectedMainHost.paymentBankDetails
                                                            })
                                                        }} />
                                                        :
                                                        this.state.selectedMainHost.company.name=="总部"?
                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                E
                                                            </div>
                                                            :
                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                B
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div style={{fontSize:13,color:"grey"}}>None</div>
                                        }
                                            
                                        <div style={{display:"flex",flexDirection:"row",gap:10,alignItems:"center"}}>
                                            <div>Other Host: </div>
                                            <Button color="primary" style={{padding:"2px 10px",fontSize:12,marginTop:5}} onClick={()=>{
                                                this.setState({selectionMode:"other"},()=>{this.getHostCompanyList()})
                                            }}>
                                                Select
                                            </Button>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"column",width:"100%",gap:2}}>
                                            {
                                            this.state.selectedOtherHost!=null&&this.state.selectedOtherHost.length>0?
                                                this.state.selectedOtherHost.map((host,index)=>{
                                                    return(
                                                        <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",fontSize:13,
                                                            display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                                            <div>
                                                                {
                                                                host.contactPerson!=null?
                                                                    host.contactPerson.username==host.contactPerson.name?
                                                                        "["+host.company.name+"] "+
                                                                        host.contactPerson.name
                                                                        :
                                                                        "["+host.company.name+"] "+
                                                                        host.contactPerson.username+" "+
                                                                        host.contactPerson.name
                                                                    :
                                                                    "["+host.company.name+"]"
                                                                }
                                                            </div>
                                                            <div style={{display:"flex",flexDirection:"row",gap:5,flexWrap:"nowrap",alignItems:"center"}}>
                                                                {
                                                                host.paymentBankDetails!=null?
                                                                    <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                                        this.setState({
                                                                            paymentBankModal:true,
                                                                            displayBankDetail:host.paymentBankDetails
                                                                        })
                                                                    }} />
                                                                    :
                                                                    host.company.name=="总部"?
                                                                        <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                            E
                                                                        </div>
                                                                        :
                                                                        <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                            B
                                                                        </div>
                                                                }
                                                                <div style={{cursor:"pointer",fontWeight:"bold",}}
                                                                    onClick={()=>{
                                                                        let otherHostClone=this.state.selectedOtherHost
                                                                        otherHostClone.splice(index,1)
                                                                        this.setState({selectedOtherHost:otherHostClone})
                                                                    }}
                                                                >
                                                                    X
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{fontSize:13,color:"grey"}}>None</div>
                                            }
                                        </div>
                                        
                                        <div>Price: </div>
                                        <Input required value={this.state.editOfflineEventPrice} style={{width:"100%"}} onChange={(e)=>{
                                            this.setState({editOfflineEventPrice:e.target.value.replace(/\D/g,"")})
                                        }} />

{
                                        this.currentType=="systemonline"?
                                            <div style={{width:"100%",display:"flex",flexDirection:"column",marginTop:5}}>
                                                <div style={{width:"100%",display:"flex",flexDirection:"row",gap:5}}>
                                                    <div>Whatsapp Group Message</div>
                                                    <Button color="primary" style={{padding:"2px 5px",fontSize:12,}} onClick={()=>{
                                                        this.setState({testWhatsappMessageModal:true})
                                                    }}>
                                                        Test
                                                    </Button>
                                                </div>
                                                <textarea required rows="5" value={this.state.editWhatsappGroupMessage} style={{width:"100%"}} onChange={(e)=>{
                                                    this.setState({editWhatsappGroupMessage:e.target.value})
                                                }} />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>

                                <div style={{marginTop:10}}>
                                    <GoogleMap
                                        setLngLat={(lat, lng) => { this.setState({ editOfflineEventVenueLat: lat, editOfflineEventVenueLng: lng }) }}
                                        setAddress={(address) => { this.setState({ editOfflineEventVenueAddress: address }) }}
                                        address={this.state.editOfflineEventVenueAddress}
                                        position={{ "lat": this.state.editOfflineEventVenueLat, "lng": this.state.editOfflineEventVenueLng }}
                                    />
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                <Input type="submit" value="Save" color="primary" />
                                <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                    this.setState({editOfflineEventModal:false})
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.addParticipantModal} toggle={()=>this.setState({addParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div>Add participant</div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center"}}>
                            <Button color={this.state.addParticipantMode=="member"?"primary":"secondary"} style={{}} onClick={()=>{
                                this.setState({addParticipantMode:"member"})
                            }}>
                                Member
                            </Button>
                            <Button color={this.state.addParticipantMode=="nonmember"?"primary":"secondary"} style={{marginLeft:10}} onClick={()=>{
                                this.setState({addParticipantMode:"nonmember",addParticipantUsername:null})
                            }}>
                                Non Member
                            </Button>
                        </div>

                        {
                        this.state.addParticipantMode=="member"?
                            <Form onSubmit={(e)=>{
                                this.addParticipantFunc()
                                e.preventDefault()
                            }}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div>
                                        Username (phone number):
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.addParticipantUsername} onChange={(e)=>{
                                        this.setState({addParticipantUsername:e.target.value})
                                    }} />
                                </div>
                                <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                    <Input type="submit" value="Add" color="primary" />
                                    <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                        this.setState({addParticipantModal:false})
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                            :
                        this.state.addParticipantMode=="nonmember"?
                            <Form onSubmit={(e)=>{
                                this.addParticipantFunc()
                                e.preventDefault()
                            }}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div>
                                        *Name:
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.nonMemberName} onChange={(e)=>{
                                        this.setState({nonMemberName:e.target.value})
                                    }} />
                                    <div>
                                        *Phone:
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.nonMemberPhone} onChange={(e)=>{
                                        this.setState({nonMemberPhone:e.target.value.replace(/\D/g,"")})
                                    }} />
                                    <div>
                                        Email:
                                    </div>
                                    <Input type="text" style={{}} value={this.state.nonMemberEmail} onChange={(e)=>{
                                        this.setState({nonMemberEmail:e.target.value})
                                    }} />
                                    <div>
                                        Industry:
                                    </div>
                                    <Input type="text" style={{}} value={this.state.nonMemberIndustry} onChange={(e)=>{
                                        this.setState({nonMemberIndustry:e.target.value})
                                    }} />
                                    <div>
                                        Referrer:
                                    </div>
                                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:10,width:"100%"}}>
                                        {
                                        this.state.selectedNonMemberReferrer!=null?
                                            <div>
                                                {this.state.selectedNonMemberReferrer.phone?
                                                    `${this.state.selectedNonMemberReferrer.phone} ${this.state.selectedNonMemberReferrer.name}` 
                                                    : 
                                                    `${this.state.selectedNonMemberReferrer.username} ${this.state.selectedNonMemberReferrer.name}`
                                                }
                                            </div>
                                            :
                                            <div>[Optional] Choose an agent</div>
                                        }
                                        <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                            this.state.isStaff?this.getAllMember():this.getCompanyAgentList()
                                        }}>
                                            Select
                                        </Button>
                                    </div>
                                </div>
                                <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                    <Input type="submit" value="Add" color="primary" />
                                    <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                        this.setState({addParticipantModal:false})
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                            :
                            null
                        }
                    </div>
                </Modal>

                <Modal style={{maxWidth:550}} isOpen={this.state.adjustSeatModal} toggle={()=>this.setState({adjustSeatModal:false})}>
                    <div style={{padding:30}}>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:this.state.ableToManageSeat?"space-between":"flex-start",alignItems:"center",fontSize:18}}>
                            {
                            this.state.ableToManageSeat?
                                <>
                                    <div style={{fontWeight:"bold"}}>
                                        Manage Seat
                                    </div>
                                    <Button color="warning" style={{fontWeight:"bold"}} onClick={() => this.getOfflineEventSeatLog()}>
                                        Seat Log
                                    </Button>
                                </>
                                :
                                <div style={{fontWeight:"bold"}}>
                                    Seat Overview
                                </div>
                            }
                        </div>
                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                        <div style={{display:"flex",flexDirection:"column",marginBottom:15,gap:5,justifyContent:"center",alignItems:"flex-start",fontSize:17}}>
                            {
                            this.state.ableToManageSeat?
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15,marginTop:5,marginBottom:5}}>
                                    <div style={{whiteSpace:"nowrap",fontWeight:600}}>Total Available Seat: </div>
                                    <Input 
                                        type="number"
                                        placeHolder={"Available Seat"}
                                        style={{
                                            fontWeight:"bold",
                                            color:(parseInt(this.state.askTotalAvailableSeat)<0)?
                                                    "red":"black"
                                        }}
                                        value={this.state.askTotalAvailableSeat} 
                                        onChange={(e)=>this.handleTotalAvailableSeatChange(e.target.value)}
                                    />
                                </div>
                                :
                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15}}>
                                    <div style={{whiteSpace:"nowrap",fontWeight:600}}>Total Available Seat: </div>
                                    <div style={{whiteSpace:"nowrap",fontWeight:"bold"}}>{this.state.askTotalAvailableSeat}</div>
                                </div>
                            }
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15}}>
                                <div style={{whiteSpace:"nowrap",fontWeight:600}}>Public Seat: </div>
                                <div style={{whiteSpace:"nowrap",fontWeight:"bold",color:this.state.askPublicSeat<0?"red":"black"}}>{this.state.askPublicSeat}</div>
                            </div>
                            <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                        </div>
                        {
                        this.state.eventHostDetailList.map((host)=>{
                            return(
                                this.state.ableToManageSeat?
                                    <div style={{display:"flex",flexDirection:"column",marginBottom:15,gap:5,justifyContent:"center",alignItems:"flex-start",fontSize:17}}>
                                        <div style={{whiteSpace:"nowrap",fontWeight:600}}>{host.type=="main"?"Main Host: [":"Other Host: ["}{host.agentCompany.name}{"]"}</div>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15,marginTop:5,marginBottom:5}}>
                                            <div style={{whiteSpace:"nowrap",fontWeight:600}}>Current Booked Seat / Seat Quota: </div>
                                            <div style={{whiteSpace:"nowrap",fontWeight:"bold"}}>{host.bookedSeat} / </div>
                                            <Input 
                                                type="number" 
                                                placeHolder={"Seat Quota"} 
                                                style={{
                                                    fontWeight:"bold",
                                                    color:(parseInt(this.state.askSeatQuotas[host.id])<host.bookedSeat)?
                                                            "red":"black"
                                                }}
                                                value={this.state.askSeatQuotas[host.id]} 
                                                onChange={(e) => this.handleSeatQuotaChange(host.id,e.target.value)}
                                            />
                                        </div>
                                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                                    </div>
                                    :
                                    <div style={{display:"flex",flexDirection:"column",marginBottom:15,gap:5,justifyContent:"center",alignItems:"flex-start",fontSize:17}}>
                                        <div style={{whiteSpace:"nowrap",fontWeight:600}}>{host.type=="main"?"Main Host: [":"Other Host: ["}{host.agentCompany.name}{"]"}</div>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15}}>
                                            <div style={{whiteSpace:"nowrap",fontWeight:600}}>Current Booked Seat / Seat Quota: </div>
                                            <div style={{whiteSpace:"nowrap",fontWeight:"bold"}}>{host.bookedSeat} / {host.seatQuota}</div>
                                        </div>
                                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                                    </div>
                            )
                        })
                        }
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,justifyContent:"space-between",gap:15}}>
                            {
                            this.state.ableToManageSeat?
                                <Button color="primary" style={{width:"100%"}} onClick={()=>{
                                    this.setState({descriptionModal:true})
                                }}>
                                    Confirm
                                </Button>
                                :
                                null
                            }
                            <Button color="secondary" style={{width:"100%"}} onClick={()=>{
                                this.setState({adjustSeatModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:550}} isOpen={this.state.descriptionModal} toggle={()=>this.setState({descriptionModal:false})}>
                    <div style={{padding:30}}>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",fontWeight:"bold",marginBottom:10}}>
                            Description for Log (Optional)
                        </div>
                        <textarea rows={5} style={{width:"100%",padding:"5px 10px"}} onChange={(e)=>{
                            this.setState({askDescription:e.target.value})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,justifyContent:"space-between",gap:15}}>
                            <Button color="primary" style={{width:"100%"}} onClick={() => this.updateEventHostSeat()}>
                                Confirm
                            </Button>
                            <Button color="secondary" style={{width:"100%"}} onClick={()=>{
                                this.setState({descriptionModal:false})
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.seatLogModal} toggle={()=>this.setState({seatLogModal:false,logPage:0,totalLogPage:0})} >
                    <div style={{padding:20}}>
                        <div style={{display:"flex",justifyContent:"center",fontSize:20,fontWeight:"bold"}}>Seat Log</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,marginBottom:20,gap:5,justifyContent:"center"}}>
                            {
                            this.state.currentLogList!=null?
                                this.state.currentLogList.map((log)=>{
                                    return(
                                        <div style={{border:"1px solid lightgrey",padding:10,borderRadius:5,width:"100%",fontSize:17,marginTop:5,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"3px"}}>
                                                <div style={{fontWeight:"700"}}>
                                                    {
                                                    log.operator?
                                                        log.operator.username==log.operator.name?
                                                            log.operator.username
                                                            :
                                                            log.operator.username+" "+log.operator.name
                                                        :"System"
                                                    }
                                                </div>
                                                <div>{log.createDate}</div>
                                            </div>
                                            <div style={{fontWeight:"500"}}>
                                                {
                                                log.description!=null?
                                                    <div>Description: {log.description}</div>
                                                    :
                                                    null
                                                }
                                                <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:7,marginBottom:7,width:"100%",color:"lightgrey"}}/>
                                                <div>
                                                    {
                                                    log.log!=null?
                                                        log.log.split("\n").map((line)=>{
                                                            return(
                                                                <div style={{}}>
                                                                    {line.replace("\t","&nbsp;&nbsp;")}
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        "-"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                            <ReactPaginate
                            pageCount={this.state.totalLogPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.logPage}
                            onPageChange={async ({selected}) => {
                                this.setState({logPage:selected},()=>{
                                    this.getOfflineEventSeatLog()
                                })
                            }}/>

                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,justifyContent:"space-between",gap:15}}>
                            <Button color="secondary" style={{width:"100%"}} onClick={()=>{
                                this.setState({seatLogModal:false,logPage:0,totalLogPage:0})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:550}} isOpen={this.state.seatOverviewModal} toggle={()=>this.setState({seatOverviewModal:false})}>
                    <div style={{padding:30}}>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",fontSize:18}}>
                            <div style={{fontWeight:"bold"}}>
                                Seat Overview
                            </div>
                            <Button color="warning" style={{fontWeight:"bold"}} onClick={() => this.getArchivedOfflineEventSeatLog()}>
                                Seat Log
                            </Button>
                        </div>
                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                        <div style={{display:"flex",flexDirection:"column",marginBottom:15,gap:5,justifyContent:"center",alignItems:"flex-start",fontSize:17}}>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15}}>
                                <div style={{whiteSpace:"nowrap",fontWeight:600}}>Total Available Seat: </div>
                                <div style={{whiteSpace:"nowrap",fontWeight:"bold"}}>{this.state.totalSeatNumber}</div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15}}>
                                <div style={{whiteSpace:"nowrap",fontWeight:600}}>Public Seat: </div>
                                <div style={{whiteSpace:"nowrap",fontWeight:"bold",color:this.state.askPublicSeat<0?"red":"black"}}>{this.state.totalPublicSeat}</div>
                            </div>
                            <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                        </div>
                        {
                        this.state.eventHostDetailList.map((host)=>{
                            return(
                                <div style={{display:"flex",flexDirection:"column",marginBottom:15,gap:5,justifyContent:"center",alignItems:"flex-start",fontSize:17}}>
                                    <div style={{whiteSpace:"nowrap",fontWeight:600}}>{host.type=="main"?"Main Host: [":"Other Host: ["}{host.agentCompany.name}{"]"}</div>
                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:15}}>
                                        <div style={{whiteSpace:"nowrap",fontWeight:600}}>Current Booked Seat / Seat Quota: </div>
                                        <div style={{whiteSpace:"nowrap",fontWeight:"bold"}}>{host.bookedSeat} / {host.seatQuota}</div>
                                    </div>
                                    <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:15,marginBottom:15,width:"100%",color:"lightgrey"}}/>
                                </div>
                            )
                        })
                        }
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,justifyContent:"space-between",gap:15}}>
                            <Button color="secondary" style={{width:"100%"}} onClick={()=>{
                                this.setState({seatOverviewModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:600}} isOpen={this.state.archivedSeatLogModal} toggle={()=>this.setState({archivedSeatLogModal:false,logPage:0,totalLogPage:0})} >
                    <div style={{padding:20}}>
                        <div style={{display:"flex",justifyContent:"center",fontSize:20,fontWeight:"bold"}}>Seat Log</div>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",marginTop:10,marginBottom:20,gap:5,justifyContent:"center"}}>
                            {
                            this.state.currentLogList!=null?
                                this.state.currentLogList.map((log)=>{
                                    return(
                                        <div style={{border:"1px solid lightgrey",padding:10,borderRadius:5,width:"100%",fontSize:17,marginTop:5,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",alignItems:"center",paddingBottom:"3px"}}>
                                                <div style={{fontWeight:"700"}}>
                                                    {
                                                    log.operator?
                                                        log.operator.username == log.operator.name?
                                                            log.operator.username
                                                            :
                                                            log.operator.username+" "+log.operator.name
                                                        :
                                                        "System"
                                                    }
                                                </div>
                                                <div>{log.createDate}</div>
                                            </div>
                                            <div style={{fontWeight:"500"}}>
                                                {
                                                log.description!=null?
                                                    <div>Description: {log.description}</div>
                                                    :
                                                    null
                                                }
                                                <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:7,marginBottom:7,width:"100%",color:"lightgrey"}}/>
                                                <div>
                                                    {
                                                    log.log!=null?
                                                        log.log.split("\n").map((line)=>{
                                                            return(
                                                                <div style={{}}>
                                                                    {line.replace("\t","&nbsp;&nbsp;")}
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        "-"
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                            }
                            <ReactPaginate
                            pageCount={this.state.totalLogPage}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            breakLabel={"..."}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                            forcePage={this.state.logPage}
                            onPageChange={async ({selected}) => {
                                this.setState({logPage:selected},()=>{
                                    this.getArchivedOfflineEventSeatLog()
                                })
                            }}/>

                        </div>
                        <div style={{display:"flex",flexDirection:"row",marginTop:30,justifyContent:"space-between",gap:15}}>
                            <Button color="secondary" style={{width:"100%"}} onClick={()=>{
                                this.setState({archivedSeatLogModal:false,logPage:0,totalLogPage:0})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <NavigationBar currentPage="offlineevent" currentType={this.currentType} />
                    {
                    window.innerWidth >= 600 ?
                        <div>
                            <div style={{display:"flex",flexDirection:"row",padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                                {
                                this.sessionMode=="coming"?
                                    <div style={{display:"flex",flexDirection:"row"}}>  
                                        {
                                        GLOBAL.checkPermission("g^%1X<EN")?
                                            <Button color="primary" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                this.createNewOfflineEvent()
                                            }}>
                                                Create new {this.eventName}
                                            </Button>
                                            :
                                            null
                                        }
                                        {
                                        this.state.currentOfflineEvent!=null?
                                            <div style={{display:"flex",flexDirection:"row"}}>
                                                {
                                                GLOBAL.checkPermission("mUb!PJ!?")?   //Edit Offline Event
                                                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                        this.editOfflineEvent()
                                                    }}>
                                                        Edit {this.eventName}
                                                    </Button>
                                                    :
                                                    null
                                                }
                                                {
                                                GLOBAL.checkPermission(">Iym(nM0")? //Delete Offline Event
                                                    <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.deleteOfflineEvent()}}>
                                                        Remove {this.eventName}
                                                    </Button>
                                                    :
                                                    null
                                                }
                                                {
                                                GLOBAL.checkPermission("`<IQy6u8")?   //Archive Offline Event
                                                    <Button color="danger" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.archiveOfflineEvent()}}>
                                                        Archive {this.eventName}
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    null
                                }

                                <div style={{flex:1}}/>
                                {
                                this.sessionMode=="coming"?
                                    GLOBAL.checkPermission("{>Qjt9>Q")? //Load List of Archived Offline Event
                                        <Button color="light" style={{marginLeft:5,border:"1px solid lightgrey"}} onClick={()=>{
                                            this.loadListofArchivedOfflineEvent()
                                        }}>
                                            Previous {this.eventName} Session
                                        </Button>
                                        :
                                        null
                                    :
                                this.sessionMode=="previous"?
                                    <Button color="light" style={{marginLeft:5,border:"1px solid lightgrey"}} onClick={()=>{
                                        this.loadOfflineEventList()
                                    }}>
                                        Coming {this.eventName} Session
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            <div style={{padding:"0px 10px"}}>
                                {
                                this.sessionMode=="coming"?
                                    <div>Coming {this.eventName} Sessions</div>
                                    :
                                this.sessionMode=="previous"?
                                    <div>Previous {this.eventName} Sessions</div>
                                    :
                                    null
                                }
                            </div>
                            <div style={{padding:10,rowGap:5,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"grid",gridTemplateRows:"auto auto",gridAutoFlow:"column",overflowX:"auto",gridAutoColumns:"max-content"}}>
                                {
                                this.state.offlineEventList.map((item)=>{
                                    return(
                                        <Button color={this.state.currentOfflineEvent&&this.state.currentOfflineEvent.id==item.id?"primary":"secondary"} style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,marginLeft:10,width:"160px",fontSize:12}}
                                            onClick={()=>{
                                                if(this.sessionMode=="coming"){
                                                    this.setState({currentOfflineEvent:item},()=>{
                                                        this.loadParticipant()
                                                    })
                                                }else if(this.sessionMode=="previous"){
                                                    this.setState({currentOfflineEvent:item},()=>{
                                                        this.loadArchivedOfflineEventParticipant()
                                                    })
                                                }
                                        }}>
                                            {item.title}<br/>
                                            {item.dateString}
                                        </Button>
                                    )
                                })
                                }
                            </div>

                            {
                            this.state.currentOfflineEvent!=null?
                                <div style={{padding:20}}>
                                    <div style={{display:"flex",flexDirection:"column",gap:5}}>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:15,width:"100%"}}>
                                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",alignItems:"center"}}>
                                                <div>Main Host:&nbsp;</div>
                                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                                    {
                                                    this.state.currentOfflineEvent.mainHost!=null?
                                                        <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",
                                                            display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                            <div style={{fontSize:13}}>
                                                                {
                                                                this.state.currentOfflineEvent.mainHost.contactPerson!=null?
                                                                    this.state.currentOfflineEvent.mainHost.contactPerson.username==this.state.currentOfflineEvent.mainHost.contactPerson.name?
                                                                        "["+this.state.currentOfflineEvent.mainHost.company.name+"] "+
                                                                        this.state.currentOfflineEvent.mainHost.contactPerson.name
                                                                        :
                                                                        "["+this.state.currentOfflineEvent.mainHost.company.name+"] "+
                                                                        this.state.currentOfflineEvent.mainHost.contactPerson.username+" "+
                                                                        this.state.currentOfflineEvent.mainHost.contactPerson.name
                                                                    :
                                                                    "["+this.state.currentOfflineEvent.mainHost.company.name+"]"
                                                                }
                                                            </div>
                                                            {
                                                            this.state.currentOfflineEvent.mainHost.paymentBankDetails!=null?
                                                                <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                                    this.setState({
                                                                        paymentBankModal:true,
                                                                        displayBankDetail:this.state.currentOfflineEvent.mainHost.paymentBankDetails
                                                                    })
                                                                }} />
                                                                :
                                                                this.state.currentOfflineEvent.mainHost.company.name=="总部"?
                                                                    <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                        E
                                                                    </div>
                                                                    :
                                                                    <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                        B
                                                                    </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div style={{fontSize:13,color:"grey"}}>None</div>
                                                    }
                                                </div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",alignItems:"center"}}>
                                                <div>Other Host:&nbsp;</div>
                                                {
                                                this.state.currentOfflineEvent.otherHost!=null&&this.state.currentOfflineEvent.otherHost.length>0?
                                                    <div style={{display:"flex",flexDirection:"row",gap:2,flexWrap:"nowrap"}}>
                                                        {
                                                        this.state.currentOfflineEvent.otherHost.map((host)=>{
                                                            return(
                                                                <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",
                                                                    display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                                    <div style={{fontSize:13}}>
                                                                        {
                                                                        host.contactPerson!=null?
                                                                            host.contactPerson.username==host.contactPerson.name?
                                                                                "["+host.company.name+"] "+
                                                                                host.contactPerson.name
                                                                                :
                                                                                "["+host.company.name+"] "+
                                                                                host.contactPerson.username+" "+
                                                                                host.contactPerson.name
                                                                            :
                                                                            "["+host.company.name+"]"
                                                                        }
                                                                    </div>
                                                                    {
                                                                    host.paymentBankDetails!=null?
                                                                        <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                                            this.setState({
                                                                                paymentBankModal:true,
                                                                                displayBankDetail:host.paymentBankDetails
                                                                            })
                                                                        }} />
                                                                        :
                                                                        host.company.name=="总部"?
                                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                                E
                                                                            </div>
                                                                            :
                                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                                B
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    :
                                                    "-"
                                                }
                                            </div>
                                        </div>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:15,width:"100%"}}>
                                            <div>
                                                Price: 
                                                {
                                                this.state.currentOfflineEvent.price!=null?
                                                    "RM"+this.state.currentOfflineEvent.price
                                                    :
                                                    "-"
                                                }
                                            </div>
                                            <div>Venue: {this.state.currentOfflineEvent.venue}</div>
                                            <div>Total participants: {this.state.participantCount}/{this.state.currentOfflineEvent.seatNumber}</div>
                                            <div style={{marginLeft:20,display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                <div style={{}}>Attendance:&nbsp;</div>
                                                {this.calculateAndGenerateAttendance()}
                                                {
                                                this.sessionMode=="coming"?
                                                    <Button color="primary" style={{marginLeft:10,padding:"5px 10px",fontSize:15}} onClick={()=>{
                                                        this.setState({ scanQRCodeModal: true,openQRCamera:false })
                                                    }}>
                                                        <AiOutlineQrcode style={{marginRight:5}} />
                                                        Scan attendance
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </div>
                                            {
                                            this.sessionMode=="previous"?
                                                GLOBAL.checkPermission(":4BIR2jF")?
                                                    <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.getArchivedEventHostDetailList()}}>
                                                        Seat Overview
                                                    </Button>
                                                    :
                                                    null
                                                :null
                                            }
                                            <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                                borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                                    copyTable()
                                                }}>
                                                <FaCopy style={{width:20,height:20,padding:4}}/>
                                                <div style={{fontSize:12}}>Copy list</div>
                                            </Button>
                                            
                                            {
                                            this.sessionMode=="previous"?
                                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                                    this.setState({
                                                        testWhatsappMessageModal:true,
                                                    })
                                                }}>
                                                    Whatsapp Group Message
                                                </Button>
                                                :
                                                null
                                            }
                                        </div>
                                        {
                                        this.sessionMode=="coming"?
                                            <div style={{display:"flex",flexDirection:"row",gap:10}}>
                                                <Button color="light" style={{border:"none",backgroundColor:"#cce4ed"}} onClick={()=>{
                                                    alert("https://www.enlliance.com/userprofile?course="+encodeURI(this.state.currentOfflineEvent.title))
                                                }}>
                                                    Attend Link
                                                </Button>
                                                {
                                                GLOBAL.checkPermission("DW_+>Ypa")? //Can add class participant UI
                                                    <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.addParticipant()}}>
                                                        Add Participant
                                                    </Button>
                                                    :
                                                    null
                                                }
                                                {
                                                GLOBAL.checkPermission("0_G%%ApA")?   //Remove Offline Event Participant
                                                    <Button color="secondary" style={{}} onClick={()=>{
                                                        this.setState({removeParticipantModal:true})
                                                    }}>
                                                        Remove Participant
                                                    </Button>
                                                    :
                                                    null
                                                }
                                                {
                                                GLOBAL.checkPermission("Em2?ZS3-")?
                                                    <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.getEventHostDetailList()}}>
                                                        {this.state.ableToManageSeat?"Manage Seat":"Seat Overview"}
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :null
                                        }

                                    </div>

                                    <Table style={{width:"100%",marginTop:10}}>
                                        <tr style={{textDecoration:"underline"}}>
                                            <td style={{}}>Phone</td>
                                            <td style={{}}>Member</td>
                                            <td style={{}}>Name</td>
                                            <td style={{}}>PIC</td>
                                            <td style={{}}>Referrer</td>
                                            <td style={{}}>Agency</td>
                                            <td style={{}}>Industry</td>
                                            <td style={{}}>Signup Date</td>
                                            <td style={{}}>Ticket Number</td>
                                            <td style={{}}>Remark</td>
                                            {
                                            this.state.currentOfflineEvent.bankDetails!=null?
                                                <td style={{}}>Paid</td>
                                                :
                                                null
                                            }
                                            <td style={{}}>Attend</td>
                                            <td style={{display:"none"}}>Attendance</td>
                                        </tr>
                                        {
                                        this.state.participantList.map((item)=>{
                                            return(
                                                <tr style={{}}>
                                                    <td style={{}}>{item.user!=null?item.user.username:item.phone}</td>
                                                    <td style={{display:"flex",flexDirection:"row",alignItems:"center",flexWrap: "wrap"}}>
                                                        <div style={{fontSize:12,color:item.user!=null?"green":"salmon",paddingRight:"6px"}}>
                                                        {
                                                        item.user!=null?
                                                            "Member"
                                                            :
                                                            <div>
                                                                <div>Non member</div>
                                                                <div>
                                                                    <span style={{textDecoration:"underline",color:"blue",cursor:"pointer"}} 
                                                                        onClick={()=>this.setState({verifyModal:true,nonMemID:item.id})}>
                                                                        Verify
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        </div>
                                                        {
                                                        item.user!=null?
                                                            item.user.membership_type=="SP"?
                                                                <div style={{marginRight:5,backgroundColor:"#fffcf2",color:"red",border:"1px solid red",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                                    SP
                                                                </div>
                                                                :
                                                                <div style={{marginRight:5,backgroundColor:"lightgrey",border:"1px solid grey",borderRadius:5,fontSize:12,padding:"2px 3px",lineHeight:1}}>
                                                                    {item.user.membership_type}
                                                                </div>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                    <td style={{fontSize:13}}>
                                                        {item.user!=null?item.user.name:item.name}
                                                    </td>
                                                    <td style={{fontSize:12}}>
                                                        {
                                                        item.user!=null&&item.user.personInCharge!=null?
                                                            item.user.personInCharge.split("\n").map((line)=>{return(<div>{line}</div>)})
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    <td style={{fontSize:12}}>
                                                        {
                                                        item.user!=null&&item.user.referrer!=null?
                                                            item.user.referrer.split("\n").map((line)=>{
                                                                return(<div>{line}</div>)
                                                            })
                                                            :
                                                        item.nonMemberReferrer!=null?
                                                            item.nonMemberReferrer.split("\n").map((line)=>{
                                                                return(<div>{line}</div>)
                                                            })
                                                            :
                                                        item.nonMemberAgentCompany!=null?
                                                            item.nonMemberAgentCompany.split("\n").map((line)=>{
                                                                return(<div>{line}</div>)
                                                            })
                                                            :
                                                            "-"
                                                        }
                                                    </td>
                                                    <td style={{fontSize:10}}>agencyasdads</td>
                                                    <td style={{fontSize:12}}>{item.industry!=null?item.industry:"-"}</td>
                                                    <td style={{fontSize:12}}>{item.signupDate}</td>
                                                    <td style={{fontSize:13}}>
                                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                            {/*<div style={{}}>{item.ticketNumber}</div>*/}
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                <Button color="light" style={{border:"1px solid lightgrey",padding:5,margin:0}} onClick={()=>{
                                                                    this.getTicketQRCodeLink(item)
                                                                }}>
                                                                    <AiOutlineQrcode />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{}}>
                                                        <Input type="text" style={{lineHeight:1,padding:3,height:"auto"}} value={item.remark!=null?item.remark:""}
                                                            onChange={(e)=>{
                                                                if(this.sessionMode=="previous"){
                                                                    return
                                                                }
                                                                let cloneParticipantList=this.state.participantList
                                                                for(let participant of cloneParticipantList){
                                                                    if(participant.ticketNumber==item.ticketNumber){
                                                                        participant.remark=e.target.value
                                                                    }
                                                                }
                                                                this.setState({participantList:cloneParticipantList})
                                                            }}
                                                            onKeyDown={(e)=>{
                                                                if(this.sessionMode=="previous"){
                                                                    return
                                                                }
                                                                if(e.keyCode==13){
                                                                    e.preventDefault()
                                                                    this.saveRemark(item.ticketNumber,e.target.value)
                                                                }
                                                            }}
                                                            onBlur={(e)=>{
                                                                if(this.sessionMode=="previous"){
                                                                    return
                                                                }else if(!GLOBAL.checkPermission("b*k]6EHR")){  //Save Offline Event Booking Remark
                                                                    return
                                                                }
                                                                this.saveRemark(item.ticketNumber,e.target.value)
                                                            }}
                                                            readOnly={!GLOBAL.checkPermission("b*k]6EHR")}    //Save Offline Event Booking Remark
                                                        />
                                                    </td>
                                                    {
                                                    this.state.currentOfflineEvent.bankDetails!=null?
                                                        <td style={{}}>
                                                            {
                                                            item.paid==true?
                                                                <Button color="success" style={{padding:"1px 9px"}} onClick={()=>{
                                                                    if(this.sessionMode=="previous"){
                                                                        return
                                                                    }
                                                                    this.markOfflineEventBookingPaid(item)
                                                                }}>
                                                                    V
                                                                </Button>
                                                                :
                                                                <Button color="secondary" style={{padding:"1px 9px"}} onClick={()=>{
                                                                    if(this.sessionMode=="previous"){
                                                                        return
                                                                    }
                                                                    this.markOfflineEventBookingPaid(item)
                                                                }}>
                                                                    X
                                                                </Button>
                                                            }
                                                        </td>
                                                        :
                                                        null
                                                    }
                                                    <td style={{}}>
                                                        {this.generateAttendanceButton(item)}
                                                    </td>
                                                    <td style={{display:"none"}}>
                                                        {
                                                            item.attendance ? (
                                                                Object.keys(item.attendance).map((key) => (
                                                                    <div key={key}>
                                                                        {key}: {item.attendance[key] ? 'present ' : 'absent '}
                                                                    </div>
                                                                ))
                                                            ) : null
                                                        }
                                                        </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </Table>
                                </div>
                                :
                                this.state.currentOfflineEvent?
                                    <div style={{padding:20}}>
                                        No participant yet
                                    </div>
                                    :null
                            }
                        </div>
                        :
                        <div style={{width:"100%"}}>
                            <div style={{width:"100%",padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"column",gap:15}}>
                                {
                                this.sessionMode=="coming"?
                                    <>
                                    <Button color="primary" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                        this.createNewOfflineEvent()
                                    }}>
                                        Create new {this.eventName}
                                    </Button>
                                    {
                                    this.state.currentOfflineEvent!=null?
                                        <div style={{padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"row",gap:10,flexWrap:"wrap"}}>
                                            {
                                            GLOBAL.checkPermission("mUb!PJ!?")?   //Edit Offline Event
                                                <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                    this.editOfflineEvent()
                                                }}>
                                                    Edit {this.eventName}
                                                </Button>
                                                :
                                                null
                                            }
                                            {
                                            GLOBAL.checkPermission(">Iym(nM0")? //Delete Offline Event
                                                <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.deleteOfflineEvent()}}>
                                                    Remove {this.eventName}
                                                </Button>
                                                :
                                                null
                                            }
                                            {
                                            GLOBAL.checkPermission("`<IQy6u8")?   //Archive Offline Event
                                                <Button color="danger" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.archiveOfflineEvent()}}>
                                                    Archive {this.eventName}
                                                </Button>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    </>
                                    :null
                                }
                                {
                                this.sessionMode=="coming"?
                                    GLOBAL.checkPermission("{>Qjt9>Q")? //Load List of Archived Offline Event
                                        <Button color="light" style={{marginLeft:5,border:"1px solid lightgrey"}} onClick={()=>{
                                            this.loadListofArchivedOfflineEvent()
                                        }}>
                                            Previous {this.eventName} Session
                                        </Button>
                                        :
                                        null
                                    :
                                this.sessionMode=="previous"?
                                    <Button color="light" style={{marginLeft:5,border:"1px solid lightgrey"}} onClick={()=>{
                                        this.loadOfflineEventList()
                                    }}>
                                        Coming {this.eventName} Session
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            <div style={{padding:"0px 10px"}}>
                                {
                                this.sessionMode=="coming"?
                                    <div>Coming {this.eventName} Sessions</div>
                                    :
                                this.sessionMode=="previous"?
                                    <div>Previous {this.eventName} Sessions</div>
                                    :
                                    null
                                }
                            </div>
                            <div style={{padding:10,rowGap:5,width:"100%",borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"grid",gridTemplateRows:"auto auto",gridAutoFlow:"column",overflowX:"auto",gridAutoColumns:"max-content"}}>
                                {
                                this.state.offlineEventList.map((item)=>{                                                                                                        
                                    return(
                                        <Button color={this.state.currentOfflineEvent&&this.state.currentOfflineEvent.id==item.id?"primary":"secondary"} style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,marginLeft:10,width:"160px",fontSize:12}}
                                            onClick={()=>{
                                                if(this.sessionMode=="coming"){
                                                    this.setState({currentOfflineEvent:item},()=>{
                                                        this.loadParticipant()
                                                    })
                                                }else if(this.sessionMode=="previous"){
                                                    this.setState({currentOfflineEvent:item},()=>{
                                                        this.loadArchivedOfflineEventParticipant()
                                                    })
                                                }
                                        }}>
                                            {item.title}<br/>
                                            {item.dateString}
                                        </Button>
                                    )
                                })
                                }
                            </div>

                            {
                            this.state.currentOfflineEvent!=null?
                                <div style={{padding:5,display:"flex",flexDirection:"column",gap:5}}>
                                    <div>Main Host:&nbsp;
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                            {
                                            this.state.currentOfflineEvent.mainHost!=null?
                                                <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",
                                                    display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                    <div style={{fontSize:13}}>
                                                        {
                                                        this.state.currentOfflineEvent.mainHost.contactPerson!=null?
                                                            this.state.currentOfflineEvent.mainHost.contactPerson.username==this.state.currentOfflineEvent.mainHost.contactPerson.name?
                                                                "["+this.state.currentOfflineEvent.mainHost.company.name+"] "+
                                                                this.state.currentOfflineEvent.mainHost.contactPerson.name
                                                                :
                                                                "["+this.state.currentOfflineEvent.mainHost.company.name+"] "+
                                                                this.state.currentOfflineEvent.mainHost.contactPerson.username+" "+
                                                                this.state.currentOfflineEvent.mainHost.contactPerson.name
                                                            :
                                                            "["+this.state.currentOfflineEvent.mainHost.company.name+"]"
                                                        }
                                                    </div>
                                                    {
                                                    this.state.currentOfflineEvent.mainHost.paymentBankDetails!=null?
                                                        <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                            this.setState({
                                                                paymentBankModal:true,
                                                                displayBankDetail:this.state.currentOfflineEvent.mainHost.paymentBankDetails
                                                            })
                                                        }} />
                                                        :
                                                        this.state.currentOfflineEvent.mainHost.company.name=="总部"?
                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                E
                                                            </div>
                                                            :
                                                            <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                B
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <div style={{fontSize:13,color:"grey"}}>None</div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        Other Host:&nbsp;
                                        {
                                        this.state.currentOfflineEvent.otherHost!=null&&this.state.currentOfflineEvent.otherHost.length>0?                                            
                                            <div style={{display:"flex",flexDirection:"row",gap:2}}>
                                                {
                                                this.state.currentOfflineEvent.otherHost.map((host)=>{
                                                    return(
                                                        <div style={{padding:5,border:"1px solid lightgrey",borderRadius:5,backgroundColor:"#f2f5fa",
                                                            display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                            <div style={{fontSize:13}}>
                                                                {
                                                                host.contactPerson!=null?
                                                                    host.contactPerson.username==host.contactPerson.name?
                                                                        "["+host.company.name+"] "+
                                                                        host.contactPerson.name
                                                                        :
                                                                        "["+host.company.name+"] "+
                                                                        host.contactPerson.username+" "+
                                                                        host.contactPerson.name
                                                                    :
                                                                    "["+host.company.name+"]"
                                                                }
                                                            </div>
                                                            {
                                                            host.paymentBankDetails!=null?
                                                                <RiMoneyDollarCircleLine style={{width:24,height:24,cursor:"pointer",color:"grey"}} onClick={()=>{
                                                                    this.setState({
                                                                        paymentBankModal:true,
                                                                        displayBankDetail:host.paymentBankDetails
                                                                    })
                                                                }} />
                                                                :
                                                                host.company.name=="总部"?
                                                                    <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                        E
                                                                    </div>
                                                                    :
                                                                    <div style={{color:"steelblue",fontSize:14,fontWeight:"bold"}}>
                                                                        B
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            :
                                            "-"
                                        }
                                    </div>
                                    <div>Price: 
                                        {
                                        this.state.currentOfflineEvent.price!=null?
                                            "RM"+this.state.currentOfflineEvent.price
                                            :
                                            "-"
                                        }
                                    </div>
                                    <div>Venue: {this.state.currentOfflineEvent.venue}</div>
                                    <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                                        <div style={{}}>Attendance:&nbsp;</div>
                                        {this.calculateAndGenerateAttendance()}
                                    </div>
                                    {
                                    this.sessionMode=="coming"?
                                        <>
                                            <Button color="primary" style={{marginLeft:10,padding:"5px 10px",fontSize:15}} onClick={()=>{
                                                this.setState({ scanQRCodeModal: true,openQRCamera:false })
                                            }}>
                                                <AiOutlineQrcode style={{marginRight:5}} />
                                                Scan attendance
                                            </Button>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:10}}>
                                                {
                                                GLOBAL.checkPermission("DW_+>Ypa")? //Can add class participant UI
                                                    <Button color="light" style={{width:"100%",marginLeft:10,padding:"5px 10px",fontSize:15,border:"1px solid lightgrey"}} onClick={()=>{this.addParticipant()}}>
                                                        Add Participant
                                                    </Button>
                                                    :
                                                    null
                                                }
                                                {
                                                GLOBAL.checkPermission("0_G%%ApA")?   //Remove Offline Event Participant
                                                    <Button color="secondary" style={{width:"100%",padding:"5px 10px",fontSize:15}} onClick={()=>{
                                                        this.setState({removeParticipantModal:true})
                                                    }}>
                                                        Remove Participant
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </div>
                                            {
                                            GLOBAL.checkPermission("Em2?ZS3-")?
                                                <Button color="light" style={{width:"100%",marginLeft:10,padding:"5px 10px",fontSize:15,border:"1px solid lightgrey"}} onClick={()=>{this.getEventHostDetailList()}}>
                                                    {this.state.ableToManageSeat?"Manage Seat":"Seat Overview"}
                                                </Button>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                    {
                                    this.sessionMode=="previous"?
                                        GLOBAL.checkPermission(":4BIR2jF")?
                                            <Button color="light" style={{borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.getArchivedEventHostDetailList()}}>
                                                Seat Overview
                                            </Button>
                                            :
                                            null
                                        :null
                                    }
                                </div>
                                :
                                null
                            }

                            {
                            this.state.participantList.length>0?
                                <div style={{padding:15}}>
                                    <div style={{display:"flex",flexDirection:"row"}}>
                                        <div>Total participants: {this.state.participantCount}</div>
                                        <div style={{marginLeft:20}}>Attendance: {this.state.attendance}/{this.state.participantList.length}</div>
                                        <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                            borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                                copyTable()
                                            }}>
                                            <FaCopy style={{width:20,height:20,padding:4}}/>
                                            <div style={{fontSize:12}}>Copy list</div>
                                        </Button>
                                    </div>
                                    <div style={{marginTop:10,gap:7,display:"flex",flexDirection:"column",width:"100%"}}>
                                        {
                                        this.state.participantList.map((item)=>{
                                            return(
                                                <div style={{width:"100%",border:"1px solid lightgrey",borderRadius:10,padding:10,fontSize:13}}>
                                                    <div style={{width:"100%",display:"flex",flexDirection:"row",gap:10,alignItems:"center"}}>
                                                        <div>{item.user?item.user.username:item.phone}</div>
                                                        <div>{item.user?item.user.name:item.name}</div>
                                                        {
                                                        item.user?
                                                            <div style={{fontSize:12,color:"white",backgroundColor:"green",borderRadius:5,padding:"2px 5px"}}>Member</div>
                                                            :
                                                            <div style={{fontSize:12,color:"white",backgroundColor:"salmon",borderRadius:5,padding:"2px 5px"}}>Non Member</div>
                                                        }
                                                    </div>
                                                    <div>PIC: {
                                                        item.user!=null?
                                                            item.user.personInCharge!=null?
                                                                item.user.personInCharge
                                                                :
                                                                "-"
                                                            :
                                                            "-"
                                                        }
                                                    </div>
                                                    <div>Referrer: {
                                                        item.user!=null&&item.user.referrer!=null?
                                                            item.user.referrer
                                                            :
                                                        item.nonMemberReferrer!=null?
                                                            item.nonMemberReferrer
                                                            :
                                                        item.nonMemberAgentCompany!=null?
                                                            item.nonMemberAgentCompany
                                                            :
                                                            "-"
                                                        }
                                                    </div>
                                                    <div style={{flex:0.1,display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"nowrap"}}>
                                                        <div>Ticket: </div>
                                                        <div style={{}}>{item.ticketNumber}</div>
                                                        <div style={{display:"flex",flexDirection:"column"}}>
                                                            <Button color="light" style={{border:"1px solid lightgrey",padding:5,margin:0}} onClick={()=>{
                                                                this.getTicketQRCodeLink(item)
                                                            }}>
                                                                <AiOutlineQrcode />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div>Signup Date: {item.signupDate}</div>
                                                    <div style={{flex:0.25,display:"flex",flexDirection:"row"}}>
                                                        Remark:
                                                        <Input type="text" style={{lineHeight:1,padding:3,height:"auto"}} value={item.remark!=null?item.remark:""}
                                                            onChange={(e)=>{
                                                                if(this.sessionMode=="previous"){
                                                                    return
                                                                }
                                                                let cloneCSD=this.state.participantList
                                                                for(let cs of cloneCSD){
                                                                    if(cs.id==item.id){
                                                                        cs.remark=e.target.value
                                                                    }
                                                                }
                                                                this.setState({participantList:cloneCSD})
                                                            }}
                                                            onKeyDown={(e)=>{
                                                                if(this.sessionMode=="previous"){
                                                                    return
                                                                }
                                                                if(e.keyCode==13){
                                                                    e.preventDefault()
                                                                    this.saveRemark(item.ticketNumber,e.target.value)
                                                                }
                                                            }}
                                                            onBlur={(e)=>{
                                                                if(this.sessionMode=="previous"){
                                                                    return
                                                                }else if(!GLOBAL.checkPermission("b*k]6EHR")){  //Save Offline Event Booking Remark
                                                                    return
                                                                }
                                                                this.saveRemark(item.ticketNumber,e.target.value)
                                                            }}
                                                            readOnly={!GLOBAL.checkPermission("b*k]6EHR")}    //Save Offline Event Booking Remark
                                                        />
                                                    </div>
                                                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",alignItems:"center",gap:10,marginTop:10,fontSize:12}}>
                                                        {
                                                        this.sessionMode=="coming"?
                                                            <div style={{color:"blue",textDecoration:"underline",cursor:"pointer"}} onClick={()=>{
                                                                if(window.confirm("Are you sure to remove this participant?")){
                                                                    if(item.user!=null){
                                                                        this.setState({removeParticipantUsername:item.user.username},()=>{
                                                                            this.removeOfflineEventParticipant()
                                                                        })
                                                                    }else{
                                                                        this.setState({removeParticipantUsername:item.phone},()=>{
                                                                            this.removeOfflineEventParticipant()
                                                                        })
                                                                    }
                                                                }
                                                            }}>
                                                                Remove
                                                            </div>
                                                            :
                                                            <div/>
                                                        }
                                                        {
                                                        item.user!=null?
                                                            <div/>
                                                            :
                                                            <div style={{textDecoration:"underline",color:"blue",cursor:"pointer"}} 
                                                                onClick={()=>this.setState({verifyModal:true,nonMemID:item.id})}>
                                                                Verify
                                                            </div>
                                                        }
                                                        {
                                                        this.state.currentOfflineEvent.bankDetails!=null?
                                                            <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                                <div>Paid</div>
                                                                {
                                                                item.paid==true?
                                                                    <Button color="success" style={{padding:"2px 5px"}} onClick={()=>{
                                                                        if(this.sessionMode=="previous"){
                                                                            return
                                                                        }
                                                                        this.markOfflineEventBookingPaid(item)
                                                                    }}>
                                                                        V
                                                                    </Button>
                                                                    :
                                                                    <Button color="secondary" style={{padding:"2px 5px"}} onClick={()=>{
                                                                        if(this.sessionMode=="previous"){
                                                                            return
                                                                        }
                                                                        this.markOfflineEventBookingPaid(item)
                                                                    }}>
                                                                        X
                                                                    </Button>
                                                                }
                                                            </div>
                                                            :
                                                            <div/>
                                                        }
                                                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:5}}>
                                                            <div>Attend</div>
                                                            {this.generateAttendanceButton(item)}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            :
                            this.state.currentOfflineEvent?
                                <div style={{padding:20}}>
                                    No participant yet
                                </div>
                            :null
                            }
                        </div>
                    }
            </div>
        )
    }
}

let styles={

}
