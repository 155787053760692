import React from 'react';
import Radium from 'radium';
import {Input,Button,Alert,Row,Col,Table,Modal,Form} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import * as GLOBAL from './global.js'

import QRCode from "qrcode.react";

import {FaCheckCircle,FaTimesCircle,FaCopy} from 'react-icons/fa';
import {AiOutlineQrcode} from 'react-icons/ai'

export default class Conference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            conferenceList:[],
            participantList:[],
            currentConference:null,
            attendance:0,

            newConferenceModal:false,
            newConferenceTitle:"",
            newConferenceVenue:"",
            newConferenceDate:null,

            editConferenceModal:false,
            editConferenceTitle:"",
            editConferenceDate:null,
            editConferenceVenue:"",

            addParticipantModal:false,
            addParticipantUsername:null,
            addParticipantMode:"member",
            nonMemberName:"",
            nonMemberPhone:"",
            nonMemberEmail:"",

            remainingQRCode:null,
            qrModal:false,
            qrCode:null,
            qrName:"",
            ticketNumber:null,

            importQRCodeModal:false,
            importQR:"",
        }
    }

    componentWillMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        this.loadConferenceList()
    }

    loadConferenceList=()=>{
        this.setState({loading:true})
        axios
            .get(`sales/loadconferencelist`)
            .then(async response => {
                //parse date
                for(var conference of response.data){
                    var rawDateSplit=conference.date.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split("+")[0].split(":")
                    conference.dateString=dateSplit[2]+"/"+dateSplit[1]+"/"+dateSplit[0]+", "+timeSplit[0]+":"+timeSplit[1]
                }

                this.sessionMode="coming"
                this.setState({
                    loading:false,
                    conferenceList:response.data,
                    currentConference:null,
                    participantList:[],
                })
            })
            .catch(error =>{
                alert("Load conference list error\n"+error)
                this.setState({loading:false})
            })
    }

    loadArchivedConferenceList=()=>{
        this.setState({loading:true})
        axios
            .post(`sales/loadarchivedconferencelist`)
            .then(async response => {
                //parse date
                for(var conference of response.data){
                    var rawDateSplit=conference.date.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split("+")[0].split(":")
                    conference.dateString=dateSplit[2]+"/"+dateSplit[1]+"/"+dateSplit[0]+", "+timeSplit[0]+":"+timeSplit[1]
                }

                this.sessionMode="previous"
                this.setState({
                    loading:false,
                    conferenceList:response.data,
                    currentConference:null,
                    participantList:[],
                })
            })
            .catch(error =>{
                alert("Load conference list error\n"+error)
                this.setState({loading:false})
            })
    }

    createNewConference=()=>{
        this.setState({newConferenceModal:true})
    }
    createNewConferenceFunc=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            title:this.state.newConferenceTitle,
            date:this.state.newConferenceDate,
            venue:this.state.newConferenceVenue,
        }
        axios
            .post(`sales/createnewconference`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    newConferenceModal:false,
                    participantList:[],
                    currentConference:null,
                })
                this.loadConferenceList()
            })
            .catch(error =>{
                alert("Create new conference modal error\n"+error)
                this.setState({loading:false,newConferenceModal:false})
            })
    }

    editConference=()=>{
        if(this.state.currentConference){
            for(var training of this.state.conferenceList){
                if(training.id==this.state.currentConference.id){
                    var rawSplit=training.date.split("+")[0]
                    var dateSplit=rawSplit.split("T")[0]
                    var timeSplit=rawSplit.split("T")[1].split(":")
                    this.setState({
                        editConferenceModal:true,
                        editConferenceTitle:training.title,
                        editConferenceDate:dateSplit+"T"+timeSplit[0]+":"+timeSplit[1],
                        editConferenceVenue:training.venue,
                    })
                    break
                }
            }
        }else{
            alert("Select a conference to edit")
        }
    }
    editConferenceFunction=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            id:this.state.currentConference.id,
            title:this.state.editConferenceTitle,
            date:this.state.editConferenceDate,
            venue:this.state.editConferenceVenue,
        }
        axios
            .post(`sales/editconference`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.setState({
                    editConferenceModal:false,
                })
                this.loadConferenceList()
                if(this.state.currentConference==null){
                    this.setState({participantList:[],loading:false})
                }else{
                    this.loadParticipant()
                }
            })
            .catch(error =>{
                alert("Edit training modal error\n"+error)
                this.setState({loading:false,editConferenceModal:false})
            })
    }

    loadParticipant=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            id:this.state.currentConference.id,
        }
        axios
            .post(`sales/loadconferenceparticipant`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //count attendance
                var att=0
                for(var booking of response.data.participantList){
                    if(booking.attend){
                        att+=1
                    }
                }

                this.setState({
                    loading:false,
                    participantList:response.data.participantList,
                    attendance:att,
                    remainingQRCode:response.data.remainingQRCode,
                })
            })
            .catch(error =>{
                alert("Load conference participant error\n"+error)
                this.setState({loading:false})
            })
    }

    loadArchivedConferenceParticipant=()=>{
        this.setState({loading:true})
        const payload = {
            id:this.state.currentConference.id,
        }
        axios
            .post(`sales/loadarchivedconferenceparticipant`,qs.stringify(payload),{timeout:30000})
            .then(async response => {
                //count attendance
                var att=0
                for(var booking of response.data){
                    if(booking.attend){
                        att+=1
                    }
                }

                this.setState({
                    loading:false,
                    participantList:response.data,
                    attendance:att,
                })
            })
            .catch(error =>{
                alert("Load conference participant error\n"+error)
                this.setState({loading:false})
            })
    }

    addParticipant=()=>{
        if(this.state.currentConference){
            this.setState({addParticipantModal:true,})
        }else{
            alert("Select a conference to add participant")
        }
    }
    addParticipantFunc=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        let payload;
        if(this.state.addParticipantUsername){
            payload = {
                conferenceID:this.state.currentConference.id,
                name:"",
                phone:this.state.addParticipantUsername,
                email:"",
                system:true,
            }
        }else{
            payload = {
                conferenceID:this.state.currentConference.id,
                name:this.state.nonMemberName,
                phone:this.state.nonMemberPhone,
                email:this.state.nonMemberEmail,
                system:true,
            }
        }

        axios
            .post(`membership/v1/signupconference`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                if(this.state.addParticipantUsername){
                    this.setState({qrName:this.state.addParticipantUsername})
                }else{
                    this.setState({qrName:this.state.nonMemberPhone})
                }
                this.setState({
                    loading:false,
                    addParticipantModal:false,
                    addParticipantUsername:null,
                    nonMemberName:"",
                    nonMemberPhone:"",
                    nonMemberEmail:"",
                    qrModal:true,
                    qrCode:response.data.qrCode,
                    ticketNumber:response.data.ticketNumber,
                })
                this.loadParticipant()
            })
            .catch(error =>{
                if(error.response){
                    if (error.response.status == 444) { //already signup with same phone
                        alert("You had already signup to the same session")
                        this.setState({
                            loading:false,
                            addParticipantUsername:null,
                            nonMemberName:"",
                            nonMemberPhone:"",
                            nonMemberEmail:"",
                        })
                        return
                    }else if(error.response.status==445){   //training no found
                        alert("Conference session not found, please refresh the page.")
                        this.setState({
                            loading:false,
                            addParticipantUsername:null,
                            nonMemberName:"",
                            nonMemberPhone:"",
                            nonMemberEmail:"",
                        })
                        return
                    }else if(error.response.status==446){   //training is already due
                        alert("The conference is already due, please refresh the page.")
                        this.setState({
                            loading:false,
                            addParticipantUsername:null,
                            nonMemberName:"",
                            nonMemberPhone:"",
                            nonMemberEmail:"",
                        })
                        return
                    }else if(error.response.status==447){
                        alert("Fail to add participant. No enough QR Code.")
                        this.setState({
                            loading:false,
                            addParticipantUsername:null,
                            nonMemberName:"",
                            nonMemberPhone:"",
                            nonMemberEmail:"",
                        })
                        return
                    }else if(error.response.status==448){
                        alert("Cannot find the user")
                        this.setState({
                            loading:false,
                            addParticipantUsername:null,
                            nonMemberName:"",
                            nonMemberPhone:"",
                            nonMemberEmail:"",
                        })
                        return
                    }
                }
                alert("Add participant error\n"+error)
                this.setState({
                    loading:false,
                    addParticipantModal:false,
                    addParticipantUsername:null,
                    nonMemberName:"",
                    nonMemberPhone:"",
                    nonMemberEmail:"",
                })
            })
    }

    removeParticipant=(removeID)=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            removeID:removeID,
        }
        axios
            .post(`sales/removeconferenceparticipant`, qs.stringify(payload),{timeout:30000})
            .then(async response => {
                this.setState({
                    loading:false,
                })
                this.loadParticipant()
            })
            .catch(error =>{
                alert("Remove participant error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    deleteConference=()=>{
        if(this.state.currentConference==null){
            alert("Select a conference to remove")
            return
        }

        if(!window.confirm("Are you sure you want to delete this conference, all unarchived booking record will be lost.")){
            return
        }

        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            conferenceID:this.state.currentConference.id,
        }
        axios
            .post(`sales/deleteconference`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.loadConferenceList()
            })
            .catch(error =>{
                alert("Remove participant error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    markConferenceAttend=(booking)=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            bookingID:booking.id,
        }
        axios
            .post(`sales/markconferenceattend`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                //mark
                let participantListClone=this.state.participantList
                for(let participant of participantListClone){
                    if(participant.id==booking.id){
                        if(booking.attend){
                            participant.attend=false
                        }else{
                            participant.attend=true
                        }
                        break
                    }
                }
                //count attendance
                var att=0
                for(let participant of participantListClone){
                    if(participant.attend){
                        att+=1
                    }
                }
                this.setState({loading:false,participantList:participantListClone,attendance:att})
            })
            .catch(error =>{
                alert("Mark attend conference error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    importQRCode=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            conferenceID:this.state.currentConference.id,
            data:this.state.importQR
        }
        axios
            .post(`sales/bulkimportconferenceqrcode`, qs.stringify(payload),{timeout:30000}) //with extra timeout
            .then(async response => {
                this.setState({
                    loading:false,
                    importQR:"",
                    importQRCodeModal:false
                })
                this.loadParticipant()
            })
            .catch(error =>{
                alert("Bulk import conference QR code error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    archiveConference=()=>{
        this.setState({loading:true})
        //get detailed list of members in charge of this staff
        const payload = {
            conferenceID:this.state.currentConference.id,
        }
        axios
            .post(`sales/archiveconference`, qs.stringify(payload),{timeout:120000}) //with extra timeout
            .then(async response => {
                this.setState({
                    loading:false,
                    currentConference:null,
                })
                this.loadConferenceList()
            })
            .catch(error =>{
                alert("Archive conference error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }

    render(){
        return(
            <div>
                {
                this.state.loading?
                    <LoadingScreen />
                    :null
                }
                <Modal style={{maxWidth:650}} isOpen={this.state.importQRCodeModal} toggle={()=>this.setState({importQRCodeModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{textAlign:"center"}}>Bulk Import QR Code</div>
                        <div style={{fontSize:12}}>Paste QR Codes separated by new line</div>
                        <textarea rows={15} style={{width:"100%",marginTop:10}} value={this.state.importQR} onChange={(e)=>{
                            this.setState({importQR:e.target.value})
                        }} />
                        <div style={{display:"flex",flexDirection:"row",marginTop:10,justifyContent:"center",width:"100%"}}>
                            <Button color="success" style={{ flex: 1, }} onClick={() => {
                                this.importQRCode()
                            }}>Import</Button>
                            <Button color="secondary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({importQRCodeModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.qrModal} toggle={()=>this.setState({qrModal:false})}>
                    <div style={{padding:20,display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <div style={{textAlign:"center"}}>Participant QR Code<br/>Please send to participant for entry</div>
                        {
                        this.state.qrCode&&
                            <QRCode
                                id="QR"
                                value={this.state.qrCode}
                                level="M"
                                renderAs="svg"
                                size={256}
                                imageSettings={{
                                    src:"https://enllianceappmedia.s3.amazonaws.com/media/photos/None/powerofsystem2.png",
                                    height:70,
                                    width:70,
                                }}
                                style={{marginTop:10}}
                            />
                        }
                        {
                        this.state.ticketNumber&&
                            <div style={{marginTop:5,fontSize:22}}>{this.state.ticketNumber}</div>
                        }
                        <div style={{display:"flex",flexDirection:"row",marginTop:10,justifyContent:"center",width:"100%"}}>
                            <Button color="success" style={{ flex: 1, }} onClick={() => {
                                let svg = document.getElementById("QR")
                                let serializedSVG = new XMLSerializer().serializeToString(svg)
                                let base64Data = window.btoa(serializedSVG)
                                let imageUrl = "data:image/svg+xml;base64," + base64Data

                                let a = document.createElement("a")
                                a.setAttribute("href", imageUrl)
                                a.setAttribute("download", this.state.qrName.replaceAll(" ", "_") + "_qrcode.svg")
                                a.dispatchEvent(new MouseEvent('click', { view: window, bubbles: false, cancelable: true }))
                            }}>Download</Button>
                            <Button color="secondary" style={{marginLeft:10}} onClick={()=>{
                                this.setState({qrModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.newConferenceModal} toggle={()=>this.setState({newConferenceModal:false})}>
                    <div style={{padding:20}}>
                        <div>Create new conference</div>
                        <Form onSubmit={(e)=>{
                            this.createNewConferenceFunc()
                            e.preventDefault()
                        }}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div>
                                    Title:
                                </div>
                                <Input type="text" required style={{}} value={this.state.newConferenceTitle} onChange={(e)=>{
                                    this.setState({newConferenceTitle:e.target.value})
                                }} />
                                <div>
                                    Conference Date:
                                </div>
                                <Input type="datetime-local" required style={{}} value={this.state.newConferenceDate} onChange={(e)=>{
                                    this.setState({newConferenceDate:e.target.value})
                                }} />
                                <div>
                                    Venue:
                                </div>
                                <Input type="text" required style={{}} value={this.state.newConferenceVenue} onChange={(e)=>{
                                    this.setState({newConferenceVenue:e.target.value})
                                }} />
                            </div>
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                <Input type="submit" value="Create" color="primary" />
                                <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                    this.setState({newConferenceModal:false})
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.editConferenceModal} toggle={()=>this.setState({editConferenceModal:false})}>
                    <div style={{padding:20}}>
                        <div>Edit conference</div>
                        <Form onSubmit={(e)=>{
                            this.editConferenceFunction()
                            e.preventDefault()
                        }}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div>
                                    Title:
                                </div>
                                <Input type="text" required style={{}} value={this.state.editConferenceTitle} onChange={(e)=>{
                                    this.setState({editConferenceTitle:e.target.value})
                                }} />
                                <div>
                                    Training Date:
                                </div>
                                <Input type="datetime-local" required style={{}} value={this.state.editConferenceDate} onChange={(e)=>{
                                    this.setState({editConferenceDate:e.target.value})
                                }} />
                                <div>
                                    Venue:
                                </div>
                                <Input type="text" required style={{}} value={this.state.editConferenceVenue} onChange={(e)=>{
                                    this.setState({editConferenceVenue:e.target.value})
                                }} />
                            </div>
                            <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                <Input type="submit" value="Save" color="primary" />
                                <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                    this.setState({editConferenceModal:false})
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal style={{maxWidth:400}} isOpen={this.state.addParticipantModal} toggle={()=>this.setState({addParticipantModal:false})}>
                    <div style={{padding:20}}>
                        <div>Add participant</div>
                        <div style={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"center"}}>
                            <Button color={this.state.addParticipantMode=="member"?"primary":"secondary"} style={{}} onClick={()=>{
                                this.setState({addParticipantMode:"member"})
                            }}>
                                Member
                            </Button>
                            <Button color={this.state.addParticipantMode=="nonmember"?"primary":"secondary"} style={{marginLeft:10}} onClick={()=>{
                                this.setState({addParticipantMode:"nonmember"})
                            }}>
                                Non Member
                            </Button>
                        </div>

                        {
                        this.state.addParticipantMode=="member"?
                            <Form onSubmit={(e)=>{
                                this.addParticipantFunc()
                                e.preventDefault()
                            }}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div>
                                        Username (phone number):
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.addParticipantUsername} onChange={(e)=>{
                                        this.setState({addParticipantUsername:e.target.value})
                                    }} />
                                </div>
                                <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                    <Input type="submit" value="Add" color="primary" />
                                    <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                        this.setState({addParticipantModal:false})
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                            :
                        this.state.addParticipantMode=="nonmember"?
                            <Form onSubmit={(e)=>{
                                this.addParticipantFunc()
                                e.preventDefault()
                            }}>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <div>
                                        Name:
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.nonMemberName} onChange={(e)=>{
                                        this.setState({nonMemberName:e.target.value})
                                    }} />
                                    <div>
                                        Phone:
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.nonMemberPhone} onChange={(e)=>{
                                        this.setState({nonMemberPhone:e.target.value})
                                    }} />
                                    <div>
                                        Email:
                                    </div>
                                    <Input type="text" required style={{}} value={this.state.nonMemberEmail} onChange={(e)=>{
                                        this.setState({nonMemberEmail:e.target.value})
                                    }} />
                                </div>
                                <div style={{display:"flex",flexDirection:"row",marginTop:20,justifyContent:"center"}}>
                                    <Input type="submit" value="Add" color="primary" />
                                    <Button color="secondary" style={{marginLeft:20}} onClick={()=>{
                                        this.setState({addParticipantModal:false})
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                            :
                            null
                        }
                    </div>
                </Modal>

                <NavigationBar currentPage="conference" />
                {
                window.innerWidth >= 600 ?
                    <div>
                        <div style={{width:"100%",display:"flex",flexDirection:"row",padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey"}}>
                            {
                            this.sessionMode=="coming"?
                                <>
                                    <Button color="primary" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewConference()}}>
                                        Create new conference
                                    </Button>
                                    {
                                    this.state.currentConference?
                                        <>
                                            <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.editConference()}}>
                                                Edit conference
                                            </Button>
                                            <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.addParticipant()}}>
                                                Add participant
                                            </Button>
                                            <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.deleteConference()}}>
                                                Remove conference
                                            </Button>
                                            <Button color="secondary" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                if(this.state.currentConference==null){
                                                    alert("Select a conference to import QR code into")
                                                    return
                                                }
                                                this.setState({importQRCodeModal:true})
                                            }}>
                                                Bulk import QR Code
                                            </Button>
                                            <Button color="danger" style={{}} onClick={()=>{
                                                if(this.state.currentConference==null){
                                                    alert("Select a conference first")
                                                    return
                                                }
                                                if(!window.confirm("Are you sure you want to archive this conference?")){
                                                    return
                                                }
                                                this.archiveConference()
                                            }}>
                                                Archive Conference
                                            </Button>
                                        </>
                                        :null
                                    }
                                </>
                                :null
                            }
                            <div style={{flex:1}} />
                            {
                            this.sessionMode=="coming"?
                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                    this.loadArchivedConferenceList()
                                }}>
                                    Previous Conference Session
                                </Button>
                                :
                            this.sessionMode=="previous"?
                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                    this.loadConferenceList()
                                }}>
                                    Coming Conference Session
                                </Button>
                                :null
                            }
                        </div>
                        <div style={{padding:"0px 10px"}}>
                            {
                            this.sessionMode=="coming"?
                                <div>Coming Conference Sessions</div>
                                :
                            this.sessionMode=="previous"?
                                <div>Previous Conference Sessions</div>
                                :
                                null
                            }
                        </div>
                        <div style={{padding:10,rowGap:5,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"grid",gridTemplateRows:"auto auto",gridAutoFlow:"column",overflowX:"auto",gridAutoColumns:"max-content"}}>
                            {
                            this.state.conferenceList.map((item)=>{
                                return(
                                    <Button color={this.state.currentConference&&this.state.currentConference.id==item.id?"primary":"secondary"} style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,marginLeft:10,width:"160px",fontSize:12}}
                                        onClick={()=>{
                                            if(this.sessionMode=="coming"){
                                                this.setState({currentConference:item},()=>{
                                                    this.loadParticipant()
                                                })
                                            }else if(this.sessionMode=="previous"){
                                                this.setState({currentConference:item},()=>{
                                                    this.loadArchivedConferenceParticipant()
                                                })
                                            }
                                    }}>
                                        {item.title}<br/>
                                        {item.dateString}
                                    </Button>
                                )
                            })
                            }
                        </div>

                        {
                        this.state.currentConference?
                            <div style={{display:"flex",flexDirection:"row"}}>
                                <div>Venue: {this.state.currentConference.venue}</div>
                                <div style={{marginLeft:20}}>Remaining QR Code: {this.state.remainingQRCode?this.state.remainingQRCode:"-"}</div>
                            </div>
                            :
                            null
                        }

                        {
                        this.state.participantList.length>0?
                            <div style={{padding:20}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <div>Total participants: {this.state.participantList.length}</div>
                                    <div style={{marginLeft:20}}>Attendance: {this.state.attendance}/{this.state.participantList.length}</div>
                                    <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                        borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                            GLOBAL.copyTable()
                                        }}>
                                        <FaCopy style={{width:20,height:20,padding:4}}/>
                                        <div style={{fontSize:12}}>Copy list</div>
                                    </Button>
                                </div>
                                <Table style={{width:"100%"}}>
                                    <tr>
                                        <td>Phone</td>
                                        <td>Member</td>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Ticket No.</td>
                                        <td>QR</td>
                                        <td>Book Date</td>
                                        <td></td>
                                        <td>Attend</td>
                                    </tr>
                                    {
                                    this.state.participantList.map((item)=>{
                                        return(
                                            <tr>
                                                <td>{item.user?item.user.username:item.phone}</td>
                                                <td>{item.user?<div style={{fontSize:12,color:"green"}}>Member</div>:<div style={{fontSize:12,color:"salmon"}}>Non Member</div>}</td>
                                                <td>{item.user?item.user.name:item.name}</td>
                                                <td>{item.user?item.user.email:item.email}</td>
                                                <td>{item.ticketNumber}</td>
                                                <td>
                                                    <Button color="light" onClick={()=>{
                                                        this.setState({
                                                            qrModal:true,
                                                            qrCode:item.qrCode,
                                                            ticketNumber:item.ticketNumber,
                                                        })
                                                    }}><AiOutlineQrcode /></Button>
                                                </td>
                                                <td>{item.bookDate.split(":")[0].replace("T",", ")+":"+item.bookDate.split(":")[1]}</td>
                                                <td>
                                                    <div style={{marginLeft:20,color:"blue",textDecoration:"underline",cursor:"pointer"}} onClick={()=>{
                                                        if(window.confirm("Are you sure to remove this participant?")){
                                                            this.removeParticipant(item.id)
                                                        }
                                                    }}>
                                                        Remove
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                    item.attend?
                                                        <Button color="success" style={{padding:"2px 5px"}} onClick={()=>{
                                                            if(this.sessionMode=="previous"){
                                                                return
                                                            }
                                                            this.markConferenceAttend(item)
                                                        }}>
                                                            V
                                                        </Button>
                                                        :
                                                        <Button color="secondary" style={{padding:"2px 5px"}} onClick={()=>{
                                                            if(this.sessionMode=="previous"){
                                                                return
                                                            }
                                                            this.markConferenceAttend(item)
                                                        }}>
                                                            X
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </Table>
                            </div>
                            :
                            this.state.currentConference?
                                <div style={{padding:20}}>
                                    No participant yet
                                </div>
                                :null
                        }
                    </div>
                    :
                    <div>
                        <div style={{width:"100%",padding:10,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"flex",flexDirection:"column",gap:10}}>
                            {
                            this.sessionMode=="coming"?
                                <>
                                    <Button color="primary" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.createNewConference()}}>
                                        Create new conference
                                    </Button>
                                    {
                                    this.state.currentConference?
                                        <>
                                            <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.editConference()}}>
                                                Edit conference
                                            </Button>
                                            <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.addParticipant()}}>
                                                Add participant
                                            </Button>
                                            <Button color="light" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{this.deleteConference()}}>
                                                Remove conference
                                            </Button>
                                            <Button color="secondary" style={{marginRight:10,borderColor:"lightgrey",borderStyle:"solid",borderWidth:1}} onClick={()=>{
                                                if(this.state.currentConference==null){
                                                    alert("Select a conference to import QR code into")
                                                    return
                                                }
                                                this.setState({importQRCodeModal:true})
                                            }}>
                                                Bulk import QR Code
                                            </Button>
                                            <Button color="danger" style={{}} onClick={()=>{
                                                if(this.state.currentConference==null){
                                                    alert("Select a conference first")
                                                    return
                                                }
                                                if(!window.confirm("Are you sure you want to archive this conference?")){
                                                    return
                                                }
                                                this.archiveConference()
                                            }}>
                                                Archive Conference
                                            </Button>
                                        </>
                                        :null
                                    }
                                </>
                                :null
                            }
                            <div style={{flex:1}} />
                            {
                            this.sessionMode=="coming"?
                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                    this.loadArchivedConferenceList()
                                }}>
                                    Previous Conference Session
                                </Button>
                                :
                            this.sessionMode=="previous"?
                                <Button color="light" style={{border:"1px solid lightgrey"}} onClick={()=>{
                                    this.loadConferenceList()
                                }}>
                                    Coming Conference Session
                                </Button>
                                :null
                            }
                        </div>
                        <div style={{padding:"0px 10px"}}>
                            {
                            this.sessionMode=="coming"?
                                <div>Coming Conference Sessions</div>
                                :
                            this.sessionMode=="previous"?
                                <div>Previous Conference Sessions</div>
                                :
                                null
                            }
                        </div>
                        <div style={{padding:10,rowGap:5,borderStyle:"solid",borderBottomWidth:1,borderLeftWidth:0,borderRightWidth:0,borderTopWidth:0,borderColor:"lightgrey",display:"grid",gridTemplateRows:"auto auto",gridAutoFlow:"column",overflowX:"auto",gridAutoColumns:"max-content"}}>
                            {
                            this.state.conferenceList.map((item)=>{
                                return(
                                    <Button color={this.state.currentConference&&this.state.currentConference.id==item.id?"primary":"secondary"} style={{borderStyle:"solid",borderColor:"lightgrey",borderWidth:1,marginLeft:10,width:"160px",fontSize:12}}
                                        onClick={()=>{
                                            if(this.sessionMode=="coming"){
                                                this.setState({currentConference:item},()=>{
                                                    this.loadParticipant()
                                                })
                                            }else if(this.sessionMode=="previous"){
                                                this.setState({currentConference:item},()=>{
                                                    this.loadArchivedConferenceParticipant()
                                                })
                                            }
                                    }}>
                                        {item.title}<br/>
                                        {item.dateString}
                                    </Button>
                                )
                            })
                            }
                        </div>

                        {
                        this.state.currentConference?
                            <div style={{display:"flex",flexDirection:"row",padding:"12px"}}>
                                <div>Venue: {this.state.currentConference.venue}</div>
                                <div style={{marginLeft:20}}>Remaining QR Code: {this.state.remainingQRCode?this.state.remainingQRCode:"-"}</div>
                            </div>
                            :
                            null
                        }

                        {
                        this.state.participantList.length>0?
                            <div style={{padding:20}}>
                                <div style={{display:"flex",flexDirection:"row",marginBottom:"10px"}}>
                                    <div>Total participants: {this.state.participantList.length}</div>
                                    <div style={{marginLeft:20}}>Attendance: {this.state.attendance}/{this.state.participantList.length}</div>
                                    <Button color="light" style={{marginLeft:10,padding:0,display:"flex",flexDirection:"row",paddingLeft:5,paddingRight:5,alignItems:"center",justifyContent:"center",
                                        borderStyle:"solid",borderColor:"lightgrey",borderWidth:1}} onClick={()=>{
                                            GLOBAL.copyTable()
                                        }}>
                                        <FaCopy style={{width:20,height:20,padding:4}}/>
                                        <div style={{fontSize:12}}>Copy list</div>
                                    </Button>
                                </div>
                                <div style={{width:"100%"}}>
                                    {/* <tr>
                                        <td>Phone</td>
                                        <td>Member</td>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Ticket No.</td>
                                        <td>QR</td>
                                        <td>Book Date</td>
                                        <td></td>
                                        <td>Attend</td>
                                    </tr> */}
                                    {
                                    this.state.participantList.map((item)=>{
                                        return(
                                            <div style={{borderRadius:"15px",padding:5,border:"1px solid",borderColor:"lightgrey",marginBottom:10}}>
                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
                                                    <div style={{display:"flex",flexDirection:"column",padding:10,width:"100%"}}>
                                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                <div style={{fontWeight:"bold",alignSelf:"flex-start",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                                                    <div style={{marginRight:10,fontSize:16}}>{item.user ? item.user.username : item.phone}</div>
                                                                    <div style={{fontSize:12,color: item.user ? "green" : "salmon"}}>{item.user ? "Member" : "Non Member"}</div>
                                                                </div>
                                                                <div style={{fontWeight:"bold",fontSize:16}}>{item.user ? item.user.name : item.name}</div>
                                                            </div>
                                                            <div style={{flex:0.05,display:"flex",flexDirection:"column"}}> Attend:
                                                                    {
                                                                        item.attend?
                                                                        <Button color="success" style={{padding:"0px 5px"}} onClick={()=>{
                                                                            if(this.sessionMode=="previous"){
                                                                                return
                                                                            }
                                                                            this.markConferenceAttend(item)
                                                                        }}>
                                                                        V
                                                                        </Button>
                                                                        :
                                                                        <Button color="secondary" style={{padding:"0px 5px"}} onClick={()=>{
                                                                            if(this.sessionMode=="previous"){
                                                                                return
                                                                            }
                                                                            this.markConferenceAttend(item)
                                                                        }}>
                                                                        X
                                                                        </Button>
                                                                    }
                                                            </div>
                                                        </div>
                                                        <div style={{color:'#000000',borderTop:"1px solid",borderRightStyle:0,marginTop:5,marginBottom:5,width:"100%",color:"lightgrey"}}/>
                                                        <div style={{marginBottom:10,fontSize:14}}>
                                                            <div style={{}}>Email: {item.user ? item.user.email : item.email}</div>
                                                            <div style={{flex:0.1,display:"flex",flexDirection:"row",alignItems:"center",gap:5,flexWrap:"nowrap"}}>
                                                                <div style={{}}>Ticket No: {item.ticketNumber}</div>
                                                                <div style={{display:"flex",flexDirection:"column",border:"1px solid",borderColor:"lightgrey"}}>
                                                                    <Button color="light" style={{padding:2}} onClick={() => {
                                                                        this.setState({
                                                                            qrModal:true,
                                                                            qrCode:item.qrCode,
                                                                            ticketNumber:item.ticketNumber,
                                                                        })
                                                                    }}>
                                                                        <AiOutlineQrcode />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div style={{alignSelf:"flex-start",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                                                <div style={{}}>Book Date: {item.bookDate.split(":")[0].replace("T",", ")+":"+item.bookDate.split(":")[1]}</div>
                                                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                                    <div>
                                                                        <div style={{marginLeft:20,color:"blue",textDecoration:"underline",cursor:"pointer"}} onClick={() => {
                                                                            if(window.confirm("Are you sure to remove this participant?")){
                                                                                this.removeParticipant(item.id)
                                                                            }
                                                                        }}>
                                                                            Remove
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            :
                            this.state.currentConference?
                                <div style={{padding:20}}>
                                    No participant yet
                                </div>
                            :
                            null
                        }
                    </div>
                }
            </div>
        )
    }
}

Conference = Radium(Conference);

let styles={

}
